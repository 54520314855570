import ApiService from "@/core/services/api.service";
import JwtService from "@/core/services/jwt.service";

export const SET_LISTA_USUARIOS_MATRICULAS = "setListUsuariosMatriculas"
export const SET_LISTA_USUARIOS = "setListUsuarios"
export const SET_LISTA_USUARIOS_PESQUISA = "setListUsuariosPesquisa"
export const SET_LISTA_MATRICULAS_USUARIO = "setListMatriculasUsuarios"
export const SET_MESSAGE_ALERT = 'setMensagem'
export const SET_MESSAGE_ALERT2 = 'setMensagem2'
export const SET_LISTA_PROFISSOES = 'setListProfissoes'

const actions = {
    async create_usuario(context, value) {
        let message = await ApiService.post('usuario/criar', value)
            .then(response => ({ tipo: 'success', message: response.data }))
            .catch((error) => ({ tipo: 'error', message: error.response.data }))
        context.commit(SET_MESSAGE_ALERT2, message)
    },
    async update_usuario(context, value) {
        let message = await ApiService.put('usuario/atualizar/' + value.id, value)
            .then(response => ({ tipo: 'success', message: response.data }))
            .catch((error) => ({ tipo: 'error', message: error.response.data }))
        context.commit(SET_MESSAGE_ALERT2, message)
    },
    async block_usuario(context, value) {
        let message = await ApiService.get('usuario/bloquear/' + value)
            .then(response => ({ tipo: 'success', message: response.data }))
            .catch((error) => ({ tipo: 'error', message: error.response.data }))
        context.commit(SET_MESSAGE_ALERT, message)
    },
    async listar_usuarios_matriculas(context, params) {
        await ApiService.get('usuario', { params: params })
            .then(response => context.commit(SET_LISTA_USUARIOS_MATRICULAS, response.data))

            .catch((error) => context.commit(SET_MESSAGE_ALERT, {
                tipo: 'error', message: error.response.data
            }))

    },
    async pesquisar_usuarios(context, value) {
        console.log(value.texto)
        await ApiService.get('usuario/' + value.filial_id + '/' + value.texto + '/' + value.status+'/'+value.tipo_pesquisa)
            .then(response => context.commit(SET_LISTA_USUARIOS_PESQUISA, response.data))
            .catch((error) => context.commit(SET_MESSAGE_ALERT, {
                tipo: 'error', message: error.response.data
            }))
    },
    async listar_medicos(context) {
        await ApiService.get('usuario/medicos')
            .then(response => context.commit(SET_LISTA_USUARIOS, response.data))
            .catch((error) => context.commit(SET_MESSAGE_ALERT, {
                tipo: 'error', message: error.response.data
            }))
    },

    async troca_senha(context, value) {
        let message = await ApiService.put('usuario/trocar/senha/' + value.id, value)
            .then(response => ({ tipo: 'success', message: response.data }))
            .catch((error) => ({ tipo: 'error', message: error.response.data }))
        context.commit(SET_MESSAGE_ALERT2, message)
    },
    async listar_usuarios(context, value) {

        await ApiService.get('usuario/perfil/' + value)
            .then(response => context.commit(SET_LISTA_USUARIOS, response.data))

            .catch((error) => context.commit(SET_MESSAGE_ALERT, {
                tipo: 'error', message: error.response.data
            }))
    },
    async listar_usuario(context, value) {

        await ApiService.get('usuario/' + value)
            .then(response => context.commit(SET_LISTA_USUARIOS, response.data))
            .catch((error) => context.commit(SET_MESSAGE_ALERT, {
                tipo: 'error', message: error.response.data
            }))
    },
    async listar_usuarios_perfil(context, value) {

        await ApiService.get('usuario/perfil/' + value.perfil_id + '/'+value.filial_id)
            .then(response => context.commit(SET_LISTA_USUARIOS_MATRICULAS, response.data))
            .catch((error) => context.commit(SET_MESSAGE_ALERT, {
                tipo: 'error', message: error.response.data
            }))

    },


    // PROFISSOES
    async listar_profissoes(context, value) {

        await ApiService.get('profissao')
            .then(response => context.commit(SET_LISTA_PROFISSOES, response.data))

            .catch((error) => context.commit(SET_MESSAGE_ALERT, {
                tipo: 'error', message: error.response.data
            }))

    },

    async create_profissao(context, value) {
        await ApiService.post('profissao', value)
            .then(response => context.commit(SET_LISTA_PROFISSOES, response.data))

            .catch((error) => context.commit(SET_MESSAGE_ALERT, {
                tipo: 'error', message: error.response.data
            }))
    },

    async buscar_usuarios(context, value) {

        await ApiService.post('usuario/buscar',value)
            .then(response => context.commit(SET_LISTA_USUARIOS, response.data))

            .catch((error) => context.commit(SET_MESSAGE_ALERT, {
                tipo: 'error', message: error.response.data
            }))
    },

    async gerar_termo_consentimento(context,value){
        
        ApiService.get("usuario/autorizacao/"+value, {
            responseType: 'blob'
        }).then(response => {
            let fileUrl = window.URL.createObjectURL(response.data);
            let fileLink = document.createElement('a');

            fileLink.href = fileUrl;
            fileLink.setAttribute('download', 'termo_consentimiento.pdf');
            document.body.appendChild(fileLink)

            fileLink.click();
        }).catch((error) => context.commit(SET_MESSAGE_ALERT, {
            tipo: 'error', message: error.response.data
        }))
    },

    async update_entrega(context, value) {
        let message = await ApiService.post('usuario/update_entrega/' + value.id, value)
            .then(response => ({ tipo: 'success', message: response.data }))
            .catch((error) => ({ tipo: 'error', message: error.response.data }))
        context.commit(SET_MESSAGE_ALERT2, message)
    },

  
};

const getters = {};

const mutations = {
    [SET_LISTA_USUARIOS_MATRICULAS](state, value) {
        state.lista_usuarios_matriculas = value
    },
    [SET_LISTA_USUARIOS_PESQUISA](state, value) {
        state.lista_usuarios_matriculas = value
    },
    [SET_LISTA_MATRICULAS_USUARIO](state, value) {
        state.lista_matriculas_usuario = value
    },
    [SET_LISTA_PROFISSOES](state, value) {
        state.lista_profissoes = value
    },

    [SET_LISTA_USUARIOS](state, value) {
        state.lista_usuarios = value
    },

    [SET_MESSAGE_ALERT](state, value) {
        let customMessage;
        if (typeof value.message === "object") {
            let item = Object.entries(value.message)[0];
            customMessage = `${item[0]}: ${item[1][0]}`;
        }

        state.mensagem_alert = {
            tipo: value.tipo,
            message: customMessage ? customMessage : value.message,
        };
       
    },
    [SET_MESSAGE_ALERT2](state, value) {
        console.log(value)
        state.mensagem_alert = {
            tipo: value.tipo,
            message: value.message,
        };
        
       
    },
};

const state = {
    lista_usuarios_matriculas: [],
    lista_matriculas_usuario: [],
    lista_usuarios: [],
    lista_profissoes: [],
    mensagem_alert:[]
};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters
};