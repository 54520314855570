import ApiService from "@/core/services/api.service";
import JwtService from "@/core/services/jwt.service";
import {SET_LISTA_FINAN_MODO_PAGAMENTO} from "@/core/store/vuex/modulos/financeiro/financeiro";

export const SET_LISTA_FINAN_ORCAMENTO_ANUAL = "setListFinanOrcamentoAnual";
export const SET_LISTA_FINAN_DEPARTAMENTO = "setListFinanDepartamento";
export const SET_LISTA_FINAN_CENTRO_CUSTO = "setListFinanCentroCusto";
export const SET_LISTA_FINAN_CENTRO_CUSTO_DEPARTAMENTO = "setListFinanCentroCustoDepartamento";
export const SET_LISTA_FINAN_PEDIDO_COMPRA = "setListFinanPedidoCompra";
export const SET_FINAN_PEDIDO_COMPRA = "setFinanPedidoCompra";
export const SET_LISTA_FINAN_APROVADOR = "setListFinanAprovador";
export const SET_LISTA_FINAN_APROVACAO = "setListFinanAprovacao";
export const SET_FINAN_NOTA_FISCAL_ENTRADA = "setFinanNotaFiscalEntrada";
export const SET_LISTA_ITEM_FINAN_PEDIDO_COMPRA = "setListFinanItemPedidoCompra";

const actions = {
    async create_finan_orcamento_anual(context, value) {
        let message = await ApiService.post('finan_orcamento_anual/criar', value)
            .then(response => ({tipo: 'success', message: response.data}))
            .catch((error) => ({tipo: 'error', message: error.response.data}))
        context.commit('SET_MESSAGE_ALERT', message)
    },
    async update_finan_orcamento_anual(context, value) {
        let message = await ApiService.put('finan_orcamento_anual/atualizar/' + value.id, value)
            .then(response => ({tipo: 'success', message: response.data}))
            .catch((error) => ({tipo: 'error', message: error.response.data}))
        context.commit('SET_MESSAGE_ALERT', message)
    },
    async listar_finan_orcamento_anual(context, value) {
        await ApiService.get('finan_orcamento_anual')
            .then(response => context.commit(SET_LISTA_FINAN_ORCAMENTO_ANUAL, response.data))
            .catch((error) => context.commit('SET_MESSAGE_ALERT', {
                tipo: 'success', message: error.response.data
            }))
    },
    async show_finan_orcamento_anual(context, value) {
        await ApiService.get('finan_orcamento_anual/' + value.id)
            .then(response => context.commit(SET_LISTA_FINAN_ORCAMENTO_ANUAL, response.data))
            .catch((error) => context.commit('SET_MESSAGE_ALERT', {
                tipo: 'success', message: error.response.data
            }))
    },
    async bloquear_finan_orcamento_anual(context, value) {

        let message = await ApiService.get('finan_orcamento_anual/bloquear/' + value.id)
            .then(response => ({tipo: 'success', message: response.data}))
            .catch((error) => ({tipo: 'error', message: error.response.data}))
        context.commit('SET_MESSAGE_ALERT', message)
    },
    async desbloquear_finan_orcamento_anual(context, value) {

        let message = await ApiService.get('finan_orcamento_anual/desbloquear/' + value.id)
            .then(response => ({tipo: 'success', message: response.data}))
            .catch((error) => ({tipo: 'error', message: error.response.data}))
        context.commit('SET_MESSAGE_ALERT', message)
    },
    async create_finan_departamento(context, value) {
        let message = await ApiService.post('finan_departamento/criar', value)
            .then(response => ({tipo: 'success', message: response.data}))
            .catch((error) => ({tipo: 'error', message: error.response.data}))
        context.commit('SET_MESSAGE_ALERT', message)

    },
    async update_finan_departamento(context, value) {
        let message = await ApiService.put('finan_departamento/atualizar/' + value.id, value)
            .then(response => ({tipo: 'success', message: response.data}))
            .catch((error) => ({tipo: 'error', message: error.response.data}))
        context.commit('SET_MESSAGE_ALERT', message)
    },
    async listar_finan_departamento(context, value) {
        await ApiService.get('finan_departamento')
            .then(response => context.commit(SET_LISTA_FINAN_DEPARTAMENTO, response.data))
            .catch((error) => context.commit('SET_MESSAGE_ALERT', {
                tipo: 'success', message: error.response.data
            }))
    },
    async show_finan_departamento(context, value) {
        await ApiService.get('finan_departamento/' + value.id)
            .then(response => context.commit(SET_LISTA_FINAN_DEPARTAMENTO, response.data))
            .catch((error) => context.commit('SET_MESSAGE_ALERT', {
                tipo: 'success', message: error.response.data
            }))
    },
    async bloquear_finan_departamento(context, value) {
        await ApiService.get('finan_departamento/bloquear/' + value.id)
            .then(response => context.commit(SET_LISTA_FINAN_MODO_PAGAMENTO, response.data))
            .catch((error) => context.commit('SET_MESSAGE_ALERT', {
                tipo: 'success', message: error.response.data
            }))
    },
    async desbloquear_finan_departamento(context, value) {
        let message = await ApiService.get('finan_departamento/desbloquear/' + value.id)
            .then(response => ({tipo: 'success', message: response.data}))
            .catch((error) => ({tipo: 'error', message: error.response.data}))
        context.commit('SET_MESSAGE_ALERT', message)
    },
    async create_finan_centro_custo(context, value) {
        let message = await ApiService.post('finan_centro_custo/criar', value)
            .then(response => ({tipo: 'success', message: response.data}))
            .catch((error) => ({tipo: 'error', message: error.response.data}))
        context.commit('SET_MESSAGE_ALERT', message)
    },
    async update_finan_centro_custo(context, value) {
        let message = await ApiService.put('finan_centro_custo/atualizar/' + value.id, value)
            .then(response => ({tipo: 'success', message: response.data}))
            .catch((error) => ({tipo: 'error', message: error.response.data}))
        context.commit('SET_MESSAGE_ALERT', message)
    },
    async listar_finan_centro_custo(context, value) {
        await ApiService.get('finan_centro_custo')
            .then(response => context.commit(SET_LISTA_FINAN_CENTRO_CUSTO, response.data))
            .catch((error) => context.commit('SET_MESSAGE_ALERT', {
                tipo: 'success', message: error.response.data
            }))
    },
    async show_finan_centro_custo(context, value) {
        await ApiService.get('finan_centro_custo/' + value.id)
            .then(response => context.commit(SET_LISTA_FINAN_CENTRO_CUSTO, response.data))
            .catch((error) => context.commit('SET_MESSAGE_ALERT', {
                tipo: 'success', message: error.response.data
            }))
    },
    async bloquear_finan_centro_custo(context, value) {
        let message = await ApiService.get('finan_centro_custo/bloquear/' + value.id)
            .then(response => ({tipo: 'success', message: response.data}))
            .catch((error) => ({tipo: 'error', message: error.response.data}))
        context.commit('SET_MESSAGE_ALERT', message)
    },
    async desbloquear_finan_centro_custo(context, value) {
        let message = await ApiService.get('finan_centro_custo/desbloquear/' + value.id)
            .then(response => ({tipo: 'success', message: response.data}))
            .catch((error) => ({tipo: 'error', message: error.response.data}))
        context.commit('SET_MESSAGE_ALERT', message)
    },
    async create_finan_centro_custo_departamento(context, value) {
        let message = await ApiService.post('finan_centro_custo_departamento/criar', value)
            .then(response => ({tipo: 'success', message: response.data}))
            .catch((error) => ({tipo: 'error', message: error.response.data}))
        context.commit('SET_MESSAGE_ALERT', message)
    },
    async update_finan_centro_custo_departamento(context, value) {
        let message = await ApiService.put('finan_centro_custo_departamento/atualizar/' + value.id, value)
            .then(response => ({tipo: 'success', message: response.data}))
            .catch((error) => ({tipo: 'error', message: error.response.data}))
        context.commit('SET_MESSAGE_ALERT', message)
    },
    async listar_finan_centro_custo_departamento(context, value) {
        await ApiService.get('finan_centro_custo_departamento')
            .then(response => context.commit(SET_LISTA_FINAN_CENTRO_CUSTO_DEPARTAMENTO, response.data))
            .catch((error) => context.commit('SET_MESSAGE_ALERT', {
                tipo: 'success', message: error.response.data
            }))
    },
    async show_finan_centro_custo_departamento(context, value) {
        await ApiService.get('finan_centro_custo_departamento/' + value.id)
            .then(response => context.commit(SET_LISTA_FINAN_CENTRO_CUSTO_DEPARTAMENTO, response.data))
            .catch((error) => context.commit('SET_MESSAGE_ALERT', {
                tipo: 'success', message: error.response.data
            }))
    },
    async bloquear_finan_centro_custo_departamento(context, value) {
        let message = await ApiService.get('finan_centro_custo_departamento/bloquear/' + value.id)
            .then(response => ({tipo: 'success', message: response.data}))
            .catch((error) => ({tipo: 'error', message: error.response.data}))
        context.commit('SET_MESSAGE_ALERT', message)
    },
    async desbloquear_produto(context, value) {
        let message = await ApiService.get('produto/desbloquear/' + value.id)
            .then(response => ({tipo: 'success', message: response.data}))
            .catch((error) => ({tipo: 'error', message: error.response.data}))
        context.commit('SET_MESSAGE_ALERT', message)
    },
    async create_finan_pedido_compra(context, value) {
        let message = await ApiService.post('finan_pedido_compra/criar', value)
            .then(response => ({tipo: 'success', message: response.data}))
            .catch((error) => ({tipo: 'error', message: error.response.data}))
        context.commit('SET_MESSAGE_ALERT', message)
    },
    async update_finan_pedido_compra(context, value) {
        let message = await ApiService.put('finan_pedido_compra/atualizar/' + value.id, value)
            .then(response => ({tipo: 'success', message: response.data}))
            .catch((error) => ({tipo: 'error', message: error.response.data}))
        context.commit('SET_MESSAGE_ALERT', message)
    },
    async listar_finan_pedido_compra(context, value) {
        await ApiService.get('finan_pedido_compra')
            .then(response => context.commit(SET_LISTA_FINAN_PEDIDO_COMPRA, response.data))
            .catch((error) => context.commit('SET_MESSAGE_ALERT', {
                tipo: 'success', message: error.response.data
            }))
    },
    async listar_finan_pedido_compra_usuario(context, value) {
        await ApiService.get('finan_pedido_compra/usuario/'+value)
            .then(response => context.commit(SET_LISTA_FINAN_PEDIDO_COMPRA, response.data))
            .catch((error) => context.commit('SET_MESSAGE_ALERT', {
                tipo: 'success', message: error.response.data
            }))
    },
    async show_finan_pedido_compra(context, id_pedido) {
        await ApiService.get('finan_pedido_compra/' + id_pedido)
            .then(response => context.commit(SET_FINAN_PEDIDO_COMPRA, response.data))
            .catch((error) => context.commit('SET_MESSAGE_ALERT', {
                tipo: 'success', message: error.response.data
            }))
    },
    async bloquear_finan_pedido_compra(context, value) {
        let message = await ApiService.get('finan_pedido_compra/bloquear/' + value.id)
            .then(response => ({tipo: 'success', message: response.data}))
            .catch((error) => ({tipo: 'error', message: error.response.data}))
        context.commit('SET_MESSAGE_ALERT', message)
    },
    async desbloquear_finan_pedido_compra(context, value) {
        let message = await ApiService.get('finan_pedido_compra/desbloquear/' + value.id)
            .then(response => ({tipo: 'success', message: response.data}))
            .catch((error) => ({tipo: 'error', message: error.response.data}))
        context.commit('SET_MESSAGE_ALERT', message)
    },
    async remover_item_finan_pedido_compra(context, value) {
        let message = await ApiService.post('finan_pedido_compra/remove_item', value)
            .then(response => ({tipo: 'success', message: response.data}))
            .catch((error) => ({tipo: 'error', message: error.response.data}))
        context.commit('SET_MESSAGE_ALERT', message)
    },
    async listar_item_finan_pedido_compra(context, value) {
        await ApiService.get('finan_pedido_compra')
            .then(response => context.commit(SET_LISTA_ITEM_FINAN_PEDIDO_COMPRA, response.data))
            .catch((error) => context.commit('SET_MESSAGE_ALERT', {
                tipo: 'success', message: error.response.data
            }))
    },
    async create_finan_aprovador(context, value) {
        let message = await ApiService.post('finan_aprovador/criar', value)
            .then(response => ({tipo: 'success', message: response.data}))
            .catch((error) => ({tipo: 'error', message: error.response.data}))
        context.commit('SET_MESSAGE_ALERT', message)
    },
    async update_finan_aprovador(context, value) {
        let message = await ApiService.put('finan_aprovador/atualizar/' + value.id, value)
            .then(response => ({tipo: 'success', message: response.data}))
            .catch((error) => ({tipo: 'error', message: error.response.data}))
        context.commit('SET_MESSAGE_ALERT', message)
    },
    async listar_finan_aprovador(context, value) {
        await ApiService.get('finan_aprovador')
            .then(response => context.commit(SET_LISTA_FINAN_APROVADOR, response.data))
            .catch((error) => context.commit('SET_MESSAGE_ALERT', {
                tipo: 'success', message: error.response.data
            }))
    },
    async show_finan_aprovador(context, value) {
        await ApiService.get('finan_aprovador/' + value.id)
            .then(response => context.commit(SET_LISTA_FINAN_APROVADOR, response.data))
            .catch((error) => context.commit('SET_MESSAGE_ALERT', {
                tipo: 'success', message: error.response.data
            }))
    },
    async bloquear_finan_aprovador(context, value) {
        let message = await ApiService.get('finan_aprovador/bloquear/' + value.id)
            .then(response => ({tipo: 'success', message: response.data}))
            .catch((error) => ({tipo: 'error', message: error.response.data}))
        context.commit('SET_MESSAGE_ALERT', message)
    },
    async desbloquear_finan_aprovador(context, value) {
        let message = await ApiService.get('finan_aprovador/desbloquear/' + value.id)
            .then(response => ({tipo: 'success', message: response.data}))
            .catch((error) => ({tipo: 'error', message: error.response.data}))
        context.commit('SET_MESSAGE_ALERT', message)
    },
    async liberar_finan_aprovacao(context, value) {
        let message = await ApiService.get('finan_aprovacao/liberar/' + value)
            .then(response => ({tipo: 'success', message: response.data}))
            .catch((error) => ({
                tipo: 'error',
                message: error.response && error.response.data ? error.response.data : 'Erro'
            }))
        context.commit('SET_MESSAGE_ALERT', message)
    },
    async gerar_finan_nota_fiscal_entrada(context, value) {
        // {headers: {'content-type':'multipart/form-data'}}
        let message = await ApiService.post('finan_nota_fiscal_entrada/gerar', value, {
            headers: {'Content-Type': 'multipart/form-data'}
        })
            .then(response => ({tipo: 'success', message: response.data}))
            .catch((error) => ({tipo: 'error', message: error.response.data}))
        context.commit('SET_MESSAGE_ALERT', message)
    },
    async show_finan_nota_fiscal_entrada(context, value) {
        await ApiService.get('finan_nota_fiscal_entrada/' + value)
            .then(response => context.commit(SET_FINAN_NOTA_FISCAL_ENTRADA, response.data))
            .catch((error) => context.commit('SET_MESSAGE_ALERT', {
                tipo: 'error', message: error.response.data
            }))
    },
    clean_nota_fiscal_entrada(context, value) {
        context.commit(SET_FINAN_NOTA_FISCAL_ENTRADA, {})
    },
    async listar_finan_aprovacao(context, params) {
        await ApiService.get('finan_aprovacao', {
            ...params
        })
            .then(response => context.commit(SET_LISTA_FINAN_APROVACAO, response.data))
            .catch((error) => context.commit('SET_MESSAGE_ALERT', {
                tipo: 'error', message: error.response.data
            }))
    },
};

const getters = {};

const mutations = {
    [SET_LISTA_FINAN_ORCAMENTO_ANUAL](state, value) {
        state.lista_finan_orcamento_anual = value
    },
    [SET_LISTA_FINAN_DEPARTAMENTO](state, value) {
        state.lista_finan_departamento = value
    },
    [SET_LISTA_FINAN_CENTRO_CUSTO](state, value) {
        state.lista_finan_centro_custo = value
    },
    [SET_LISTA_FINAN_CENTRO_CUSTO_DEPARTAMENTO](state, value) {
        state.lista_finan_centro_custo_departamento = value
    },
    [SET_LISTA_FINAN_PEDIDO_COMPRA](state, value) {
        state.lista_finan_pedido_compra = value
    },
    [SET_FINAN_PEDIDO_COMPRA](state, value) {
        state.finan_pedido_compra = value
    },
    [SET_LISTA_FINAN_APROVADOR](state, value) {
        state.lista_finan_aprovador = value
    },
    [SET_LISTA_ITEM_FINAN_PEDIDO_COMPRA](state, value) {
        state.lista_item_finan_pedido_compra = value
    },
    [SET_LISTA_FINAN_APROVACAO](state, value) {
        state.lista_finan_aprovacao = value
    },
    [SET_FINAN_NOTA_FISCAL_ENTRADA](state, value) {
        state.finan_nota_fiscal_entrada = value
    },
};

const state = {
    lista_finan_orcamento_anual: [],
    lista_finan_departamento: [],
    lista_finan_centro_custo: [],
    lista_finan_centro_custo_departamento: [],
    lista_finan_pedido_compra: [],
    finan_pedido_compra: {},
    lista_finan_aprovador: [],
    lista_item_finan_pedido_compra: [],
    lista_finan_aprovacao: [],
    finan_nota_fiscal_entrada: {}
};

export default {
    namespaced:true,
    state,
    actions,
    mutations,
    getters
};