import Vue from "vue";
import Router from "vue-router";

Vue.use(Router);

export default new Router({
    mode: 'history',
    routes: [
        {
            path: "/fila",
            name: "fila",
            component: () => import("@/view/components/atendimento/fila.vue"),
            meta: { title: 'Fila' }
        },
        {
            path: "/",
            redirect: "/login", // aqui estava dashboard 
            component: () => import("@/view/layout/Layout"),
            children: [
                {
                    path: "/dashboard",
                    name: "dashboard",
                    component: () => import("@/view/pages/dadosEmpresa.vue"),
                    meta: { title: 'Dashboard' }
                },
              


                {
                    path: "/createFuncionario",
                    name: "createFuncionario",
                    component: () => import("@/view/components/rh/createFuncionario.vue")
                },

                {
                    path: "/gerenFuncionarios",
                    name: "gerenFuncionarios",
                    component: () => import("@/view/components/rh/gerenFuncionario.vue")
                },
                {
                    path: "/createPaciente",
                    name: "createPaciente",
                    component: () => import("@/view/components/paciente/createPaciente.vue")
                },

                {
                    path: "/gerenPaciente",
                    name: "gerenPaciente",
                    component: () => import("@/view/components/paciente/gerenPaciente.vue")
                },
                {
                    path: "/atendimentosPaciente/:usuario_id?",
                    name: "atendimentosPaciente",
                    component: () => import("@/view/components/paciente/atendimentosPaciente.vue")
                },
                {
                    path: "/permissoes",
                    name: "permissoes",
                    component: () => import("@/view/components/config/permissons/permissoes.vue")
                },
                {
                    path: "/createRule",
                    name: "createRule",
                    component: () => import("@/view/components/config/permissons/createRule")
                },



                /* ------------------------------ GERENCIAMENTO ----------------------------- */
                //#region GERENCIAMENTO

                ////EMPRESA////
                {
                    path: "/empresa",
                    name: "empresa",
                    component: () => import("@/view/components/gerenciamento/empresa/listaEmpresa.vue")
                },

                {
                    path: "/createEmpresa",
                    name: "createEmpresa",
                    component: () => import("@/view/components/gerenciamento/empresa/createEmpresa.vue")
                },

                ////FIM EMPRESA///
                //////FILIAL/////
                {
                    path: "/filial",
                    name: "filial",
                    component: () => import("@/view/components/gerenciamento/filial/listaFilial.vue")
                },
                {
                    path: "/createFilial",
                    name: "createFilial",
                    component: () => import("@/view/components/gerenciamento/filial/createFilial.vue")
                },
                ////FIM FILIAL///
                {
                    path: "/sala",
                    name: "sala",
                    component: () =>
                        import("@/view/components/config/sala/listaSala.vue")
                },

                {
                    path: "/createSala",
                    name: "createSala",
                    component: () =>
                        import("@/view/components/config/sala/createSala.vue")
                },
                {
                    path: "/createEspecialidade",
                    name: "createEspecialidade",
                    component: () =>
                        import("@/view/components/config/especialidades/createEspecialidade.vue")
                },
                {
                    path: "/listaEspecialidade",
                    name: "listaEspecialidade",
                    component: () =>
                        import("@/view/components/config/especialidades/listaEspecialidade.vue")
                },
                {
                    path: "/vinculo_sala",
                    name: "vinculo_sala",
                    component: () =>
                        import("@/view/components/config/vinculo_sala_especialidade/listaVinculos.vue")
                },
                {
                    path: "/create_vinculo_sala",
                    name: "create_vinculo_sala",
                    component: () => 
                        import("@/view/components/config/vinculo_sala_especialidade/create.vue")
                },
                {
                    path: "/agendamentos",
                    name: "agendamentos",
                    component: () =>
                        import("@/view/components/gerenciamento/consultorios/gerenciar.vue")
                },
                
                {
                    path: "/local",
                    name: "local",
                    component: () =>
                        import("@/view/components/config/local/listaLocal.vue")
                },

                {
                    path: "/createLocal",
                    name: "createLocal",
                    component: () =>
                        import("@/view/components/config/local/createLocal.vue")
                },
                {
                    path: "/perfil",
                    name: "perfil",
                    component: () =>
                        import("@/view/components/config/perfil/listaPerfil.vue")
                },

                {
                    path: "/createPerfil",
                    name: "createPerfil",
                    component: () =>
                        import("@/view/components/config/perfil/createPerfil.vue")
                },
                {
                    path: "/setor",
                    name: "setor",
                    component: () =>
                        import("@/view/components/config/setor/listaSetor.vue")
                },

                {
                    path: "/createSetor",
                    name: "createSetor",
                    component: () =>
                        import("@/view/components/config/setor/createSetor.vue")
                },


                {
                    path: "/atendimento",
                    name: "atendimento",
                    component: () =>
                        import("@/view/components/atendimento/listaAtendimento.vue")
                },
                {
                    path: "/triaje",
                    name: "triaje",
                    component: () =>
                        import("@/view/components/atendimento/listaTriaje.vue")
                },
                {
                    path: "/consulta",
                    name: "consulta",
                    component: () =>
                        import("@/view/components/atendimento/listaConsultas.vue")
                },
                {
                    path: "/filaAtendimentos",
                    name: "filaAtendimentos", 
                    component: () =>
                    import("@/view/components/fila/docente/filaAtendimentos.vue")
                },

                {
                    path: "/createAtendimento",
                    name: "createAtendimento",
                    component: () =>
                        import("@/view/components/atendimento/createAtendimento.vue")
                },

                {
                    path: "/createOcorrencia/:atendimento_id?",
                    name: "createOcorrencia",
                    component: () => import("@/view/components/ocorrencia/createOcorrencia.vue")
                },
                {
                    path: "/produto",
                    name: "produto",
                    component: () => import("@/view/components/farmacia/produto/listaProduto.vue")
                },

                {
                    path: "/createProduto",
                    name: "createProduto",
                    component: () => import("@/view/components/farmacia/produto/createProduto.vue")
                },
                {
                    path: "/createProduto/:produto_id?",
                    name: "produtoDados",
                    component: () => import("@/view/components/farmacia/produto/createProduto.vue")
                },
                {
                    path: "/laboratorio",
                    name: "laboratorio",
                    component: () => import("@/view/components/farmacia/Laboratorio/listaLaboratorio.vue")
                },
                {
                    path: "/CreateLaboratorio",
                    name: "createLaboratorio",
                    component: () => import("@/view/components/farmacia/Laboratorio/createLaboratorio.vue")
                },
                {
                    path: "/tipoProduto",
                    name: "tipoProduto",
                    component: () => import("@/view/components/farmacia/tipoProduto/listaTipoProduto.vue")
                },
                {
                    path: "/createTipoProduto",
                    name: "createTipoProduto",
                    component: () => import("@/view/components/farmacia/tipoProduto/createTipoProduto.vue")
                },
                {
                    path: "/substancia",
                    name: "substancia",
                    component: () => import("@/view/components/farmacia/substancia/listaSubstancia.vue")
                },
                {
                    path: "/createSubstancia",
                    name: "createSubstancia",
                    component: () => import("@/view/components/farmacia/substancia/createSubstancia.vue")
                },
                {
                    path: "/createrelatorio",
                    name: "createrelatorio",
                    component: () => import("@/view/components/farmacia/relatorio/createrelatorio.vue")
                },
                {
                    path: "/relatorioEstoque",
                    name: "relatorioEstoque",
                    component: () => import("@/view/components/farmacia/relatorio/relatorioEstoque.vue")
                },
                {
                    path: "/tipoClasse",
                    name: "tipoClasse",
                    component: () => import("@/view/components/farmacia/tipoClasse/listaTipoClasse.vue")
                },
                {
                    path: "/createTipoClasse",
                    name: "createTipoClasse",
                    component: () => import("@/view/components/farmacia/tipoClasse/createTipoClasse.vue")
                },
                {
                    path: "/saidaProduto",
                    name: "saidaProduto",
                    component: () => import("@/view/components/farmacia/saidaProduto/saidaProduto.vue")
                },
                {
                    path: "/createSaidaProduto",
                    name: "createSaidaProduto",
                    component: () => import("@/view/components/farmacia/saidaProduto/createSaidaProduto.vue")
                },

                {
                    path: "/relatorioSaidas",
                    name: "relatorioSaidas",
                    component: () => import("@/view/components/relatorios/saidaProduto.vue")
                },
                {
                    path: "/listReceitas",
                    name: "listReceitas",
                    component: () => import("@/view/components/farmacia/saidaProduto/listReceitas.vue")
                },

                {
                    path: "/estoque",
                    name: "estoque",
                    component: () => import("@/view/components/farmacia/estoque/estoque.vue")
                },
                {
                    path: "/estoquesAtivos",
                    name: "estoquesAtivos",
                    component: () => import("@/view/components/relatorios/relatorioEstoque.vue")
                },
                {
                    path: "/relatorioGeral",
                    name: "relatorioGeral",
                    component: () => import("@/view/components/relatorios/relatorioGeral.vue")
                },
                {
                    path: "/relatorioFilial",
                    name: "relatorioFilial",
                    component: () => import("@/view/components/relatorios/relatorioFilial.vue")
                },
                {
                    path: "/transferencias",
                    name: "transferencias",
                    component: () => import("@/view/components/farmacia/relatorio/transferenciaEstoque.vue")
                },

                {
                    path: "/createDiagnostico",
                    name: "createDiagnostico",
                    component: () => import("@/view/components/config/diagnostico/createDiagnostico.vue")
                },

                {
                    path: "/listarDiagnostico",
                    name: "listarDiagnostico",
                    component: () => import("@/view/components/config/diagnostico/listarDiagnostico.vue")
                },

                {
                    path: "/relatorioAtendimentos",
                    name: "relatorioAtendimentos",
                    component: () => import("@/view/components/relatorios/relatorioAtendimentos.vue")
                },










            ]

        },

        {
            path: "/",
            component: () => import("@/view/pages/auth/Login"),
            children: [
                {
                    name: "login",
                    path: "/login",
                    component: () => import("@/view/pages/auth/Login")
                },
                {
                    name: "register",
                    path: "/register",
                    component: () =>
                        import("@/view/pages/auth/Login")
                },


            ]
        },
        {
            path: "*",
            redirect: "/404"
        },
   
        {
            path: "/404",
            name: "404",
            component: () => import("@/view/pages/error/Error-1.vue"),
        },
        // {
        //     path: "/comprovante",
        //     name: "comprovante",
        //     component: () =>
        //         import ("@/view/components/financeiro/comprovante.vue")
        // },

    ]
    
});