import ApiService from "@/core/services/api.service";
import JwtService from "@/core/services/jwt.service";

export const SET_LISTA_ATENDIMENTO = "setListAtendimento"
export const SET_MESSAGE_ALERT = "setMensagem"
export const SET_ATENDIMENTO_ID = "setAtendimentoID"
export const SET_ATENDIMENTO = "setAtendimento"
export const SET_LISTA_OCORRENCIAS_ATENDIMENTO = "setOcorrenciasAtendimento"


const actions = {
    async create_atendimento(context, value) {
        let data = await ApiService.post('atendimento/criar', value)
            .then(response => ({ tipo: 'success', message: response.data }))
            .catch((error) => ({ tipo: 'error', message: error.response.data }))
        context.commit(SET_MESSAGE_ALERT, data)
        context.commit(SET_ATENDIMENTO_ID, data.message.conteudo)
    },
    async update_atendimento(context, value) {
        let message = await ApiService.put('atendimento/atualizar/' + value.id, value)
            .then(response => ({ tipo: 'success', message: response.data }))
            .catch((error) => ({ tipo: 'error', message: error.response.data }))
        context.commit(SET_MESSAGE_ALERT, message)
    },
    async bloquear_atendimento(context, value) {
        let message = await ApiService.put('atendimento/bloquear/' + value.id)
            .then(response => ({ tipo: 'success', message: response.data }))
            .catch((error) => ({ tipo: 'error', message: error.response.data }))
        context.commit(SET_MESSAGE_ALERT, message)
    },
    async finalizar_atendimento(context, value) {
        let message = await ApiService.put('atendimento/finalizar/' + value.id)
            .then(response => ({ tipo: 'success', message: response.data }))
            .catch((error) => ({ tipo: 'error', message: error.response.data }))
        context.commit(SET_MESSAGE_ALERT, message)
    },
    async listar_atendimentos(context) {
        await ApiService.get('atendimento')
            .then(response => context.commit(SET_LISTA_ATENDIMENTO, response.data))
            .catch((error) => context.commit(SET_MESSAGE_ALERT, {
                tipo: 'error', message: error.response.data
            }))
    },
    async lista_atendimento_ocorrencias(context, value) {
        await ApiService.get('ocorrencia/atendimento/' + value.atendimento_id)
            .then(response => context.commit(SET_LISTA_OCORRENCIAS_ATENDIMENTO, response.data))
            .catch((error) => context.commit(SET_MESSAGE_ALERT, {
                tipo: 'error', message: error.response.data
            }))
    },
    async listar_atendimentos_paciente(context, value) {
        await ApiService.get('atendimento/usuario/' + value)
            .then(response => context.commit(SET_LISTA_ATENDIMENTO, response.data))
            .catch((error) => context.commit(SET_MESSAGE_ALERT, {
                tipo: 'error', message: error.response.data
            }))
    },
    async show_atendimento(context, value) {
        await ApiService.get('atendimento/' + value.atendimento_id)
            .then(response => context.commit(SET_ATENDIMENTO, response.data))
            .catch((error) => context.commit(SET_MESSAGE_ALERT, {
                tipo: 'error', message: error.response.data
            }))
    },
    async troca_senha(context, value) {
        let message = await ApiService.put('atendimento/trocar/senha/' + value.id, value)
            .then(response => ({ tipo: 'success', message: response.data }))
            .catch((error) => ({ tipo: 'error', message: error.response.data }))
        context.commit(SET_MESSAGE_ALERT, message)
    },
    async pesquisar_atendimentos(context, value) {
        await ApiService.post('atendimento/pesquisar', value)
            .then(response => context.commit(SET_LISTA_ATENDIMENTO, response.data))
            .catch((error) => context.commit(SET_MESSAGE_ALERT, {
                tipo: 'error', message: error.response.data
            }))
    },
    // async relatorio_atendimentos(context) {
    //     await ApiService.get('atendimento/relatorio')
    //         .then(response => context.commit(SET_LISTA_ATENDIMENTO, response.data))
    //         .catch((error) => context.commit(SET_MESSAGE_ALERT, {
    //             tipo: 'error', message: error.response.data
    //         }))
    // },
    async relatorio_atendimentos(context, filtro) {
        await ApiService.post('atendimento/relatorio', filtro)
            .then(response => context.commit(SET_LISTA_ATENDIMENTO, response.data))
            .catch((error) => context.commit(SET_MESSAGE_ALERT, {
                tipo: 'error', message: error.response.data
            }))
    },
    async media_atendimentos_filial(context, filtro) {
      let data =  await ApiService.post('atendimento/relatorio/media', filtro)
        
            .then(response => context.commit(SET_LISTA_ATENDIMENTO, response.data))
            .catch((error) => context.commit(SET_MESSAGE_ALERT, {
                tipo: 'error', message: error.response.data
            }))
            console.log(data)
           
    },
    
    async relatorio_atendimentos_excel(context, filtro) {
         await   ApiService.post("atendimento/relatorio/excel",filtro, {
                responseType: 'blob'
            }).then(response => {
                let fileUrl = window.URL.createObjectURL(response.data);
                let fileLink = document.createElement('a');
    
                fileLink.href = fileUrl;
                fileLink.setAttribute('download', 'relatorio_atendimentos.xlsx');
                document.body.appendChild(fileLink)
    
                fileLink.click();
            }).catch((error) => context.commit(SET_MESSAGE_ALERT, {
                tipo: 'error', message: error.response.data
            }))
    },

//     async relatorio_atendimentos_excel(context, filtro) {
//         let data =  await ApiService.post('atendimento/relatorio/excel', filtro)
        
//         .then(response => context.commit(SET_LISTA_ATENDIMENTO, response.data))
//         .catch((error) => context.commit(SET_MESSAGE_ALERT, {
//             tipo: 'error', message: error.response.data
//         }))
//         console.log(data)
//    },


};

const getters = {};

const mutations = {
    [SET_LISTA_ATENDIMENTO](state, value) {
        state.lista_atendimentos = value
    },
    [SET_ATENDIMENTO_ID](state, value) {
        state.atendimento_id = value
    },
    [SET_ATENDIMENTO](state, value) {
        state.atendimento = value
    },
    [SET_LISTA_OCORRENCIAS_ATENDIMENTO](state, value) {
        state.lista_ocorrencias_atendimentos = value
    },

    [SET_MESSAGE_ALERT](state, value) {
        console.log(value)
        let customMessage;
        if (typeof value.message === "object") {

            customMessage = value.message.msg;
        }
        state.mensagem_alert = {
            tipo: value.tipo,
            message: customMessage ? customMessage : value.message,
        };
    },

};

const state = {
    lista_atendimentos: [],
    mensagem_alert: "",
    atendimento_id: '',
    atendimento: [],
    lista_ocorrencias_atendimentos: ''
};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters
};