// Spain
export const locale = {
  TRANSLATOR: {
    SSELECT: "Escolha seu idioma"
  },
  MENU: {
    NEW: "Novo",
    ACTIONS: "Comportamiento",
    CREATE_POST: "Criar nova postagem",
    PAGES: "Paginas",
    FEATURES: "Caracteristicas",
    APPS: "Aplicativos",
    DASHBOARD: "Tablero"
  },
  AUTH: {
    GENERAL: {
      OR: "O",
      SUBMIT_BUTTON: "Enviar",
      NO_ACCOUNT: "Não tem uma conta?",
      SIGNUP_BUTTON: "Cadastrar",
      FORGOT_BUTTON: "Esqueceu sua senha",
      BACK_BUTTON: "Espalda",
      PRIVACY: "Intimidad",
      LEGAL: "Legal",
      CONTACT: "Cotato",
      TROCAR:"Trocar Senha"
    },
    LOGIN: {
      TITLE: "Criar uma conta",
      BUTTON: "Cadastrar-se"
    },
    FORGOT: {
      TITULO: "Senha esquecida?",
      DESC: "Insira seu e-mail para redefinir sua senha",
      SUCCESS: "Sua conta foi redefinida com sucesso."
    },
    REGISTER: {
      TITLE: "Cadastrar",
      DESC: "Insira os dados para criar sua conta",
      SUCCESS: "Sua conta foi registrada com sucesso.",
      DSENHA:"Digite sua senha nova",
      CSENHA:"Confirme a nova senha",
      DSENHAATUAL:"Digite sua senha atual"
    },
    INPUT: {
      EMAIL: "Email",
      FULLNAME: "Nome Completo",
      PASSWORD: "Senha",
      CONFIRM_PASSWORD: "Confirmar senha",
      USERNAME: "Usuario"
    },
    VALIDATION: {
      INVALID: "{{name}} não é válido",
      REQUIRED: "{{name}} é necessário",
      MIN_LENGTH: "{{name}} comprimento mínimo é {{min}}",
      AGREEMENT_REQUIRED: "Aceitar os termos e condições são obrigatórios",
      NOT_FOUND: "O {{name}}  solicitado não foi encontrado",
      INVALID_LOGIN: "Os detalhes de login estão incorretos",
      REQUIRED_FIELD: "Campo obrigatório",
      MIN_LENGTH_FIELD: "Comprimento mínimo do campo:",
      MAX_LENGTH_FIELD: "Comprimento máximo do campo:",
      INVALID_FIELD: "O campo não é válido"
    }
  },
  ECOMMERCE: {
    COMMON: {
      SELECTED_RECORDS_COUNT: "Contagem de registros selecionados: ",
      ALL: "All",
      SUSPENDED: "Suspenso",
      ACTIVE: "Ativo",
      FILTER: "Filtrar",
      BY_STATUS: "by Status",
      BY_TYPE: "by Type",
      BUSINESS: "Business",
      INDIVIDUAL: "Individual",
      SEARCH: "Search",
      IN_ALL_FIELDS: "in all fields"
    },
    ECOMMERCE: "eCommerce",
    CLIENTES: {
      CLIENTES: "Clientes",
      LISTA_CLIENTES: "Lista clientes",
      NOVO_CLIENTE: "Novo cliente",
      EXCLUIR_CLIENTE: {
        TITULO: "Excluir cliente",
        DESCRICAO: "Tem certeza de que deseja excluir permanentemente este cliente?",
        WAIT_DESCRIPTION: "Os clientes estão sendo eliminados..",
        MENSAGEM: "Os clientes selecionados foram excluídos"
      },
      EXCLUIR_LISTA_CLIENTES: {
        TITULO: "Excluir clientes",
        DESCRICAO: "Tem certeza de excluir permanentemente os clientes selecionados?",
        WAIT_DESCRIPTION: "Os clientes estão sendo eliminados...",
        MENSAGEM: "Os clientes selecionados foram excluídos"
      },
      ATUALIZACAO_STATUS: {
        TITULO: "O status foi atualizado para os clientes selecionados",
        MENSAGEM: "O status dos clientes selecionados foi atualizado com sucesso"
      },
      EDITAR: {
        ATUALIZACAO_MENSAGEM: "O cliente foi atualizado",
        MENSAGEM_CRIACAO: "O cliente foi criado"
      }
    },

   // CREATE PACIENTE

  }, 
  FUNCIONARIO: {
    DASHBOARD: "Gerenciar funcionarios"
  },
  PACIENTES: {
    DASHBOARD: "Gerenciar pacientes"
  },
  ESTOQUES: {
    DASHBOARD: "Gerenciar estoques",
    ATIVOS:"Estoques",
    VALIDADE:"Validad",
    INICIOVALIDADE:"Data inicio de validade",
    FIMVALIDADE:"Data final de validade",

  },
  ATENDIMENTO: {
    NOME: "Atendimento",
    DASHBOARD: "Gerenciar atendimentos"
  },
  GERENCIAR:{ 
    CRIAR:"Criar",
    NOME:"Salvar",
  },
  LABORATORIO:{
    NOVO_LAB:"Novo Laboratorio"
  },
  PRODUTOS:{
    PRODUTO:"Produtos",
    NOVO_PRODUTO:"Novo Produto",
    ESTOQUE:{
      descricao_valor_total:"Aqui fica o valor TOTAL pago no estoque fechado",
      descricao_valor_unitario:"Este valor es calculado automáticamente despues que ya haya valor total y cantidad total llenados"
    }
  },
  RELATORIO:{
    FILIAL:"MAIS RELATORIOS",
    RELATORIOS:"RELATORIOS"
  },
  SUBSTANCIA:{
    NOVA_SUBSTANCIA:"Nova Substancia",
  },
  TIPO:{
    NOVO_TIPO:"Novo Tipo",
  },
  LOCAL:{
    NOVO_LOCAL:"Novo Local",
  },
  SETOR:{
    NOVO_SETOR:"Novo Setor",
  },
  PERFIL:{
    NOVO_PERFIL:"Novo Perfil",
  },
  SALA:{
    NOVA_SALA:"Nova Sala",
  },
  VINCULO:{
    NOVO_VINCULO:"Novo Vinculo",
  },
  SALVAR:{
    NOME:"Salvar",
  },
  GUARDARDATOS:{
    NOME:"Salvar",
  },
  ATIVO:{
    NOME:"Ativo",
  },
  DESATIVADO:{
    NOME:"Desativado",
  },
  SUSPENSO:{
    NOME:"Suspenso",
  },
  DESCRICAO:{
    NOME:"Descrição",
  },
  ESTADO:{
    NOME:"Status:",
  },
  DESCRIBIR:{
    NOME:"Digite a descrição...",
  },
  BAIRRO:{
    NOME:"Bairro:",
  },
  ENDERECO:{
    NOME:"Endereço:",
  },
  CIDADE:{
    NOME:"Cidade:",
  },
  ESTADO2:{
    NOME:"Estado:",
  },
  NATURALIDADE:{
    NOME:"Naturalidade:",
  },
  NOMBRE:{
    NOME:"Nome:",
  },
  TELEFONO1:{
    NOME:"Telefone 1:",
  },
  TELEFONO2:{
    NOME:"Telefone 2:",
  },
  NUMERO:{
    NOME:"Numero:",
  },
  SOBRENOME:{
    NOME:"Sobrenome:",
  },
  DATA:{
    NOME:"D. Nascimento:",
    IDADE:"Idade"
  },
  PROFESION:{
    NOME:"Profissão:",
  },
 
 // CREATE ATENDIMIENTO

 DATAINICIO:{
  NOME:"Data Inicio:",
},
DATAFINAL:{
  NOME:"Data Final:",
},
ATENCIONES:{
  NOME:"Atendimento:",
},
 // CREATE OCORRENCIA

 FECHA:{
  NOME:"Data:",
},
OCORRENCIAS:{
  NOME:"Ocorrencias:",
},
TRIAGEM:{
  NOME:"Triagem",
},
RECEITA:{
  NOME:"Receita Médica",
},
DESCRPCION_RECEITA:{
  NOME:"Insumos e Produtos utilizados no paciente",
},
// CONSULTA
MOTIVO_CONSULTA:{
  NOME:"Motivo da Consulta (MC):*",
},
DESCRIP_CONSULTA:{
  NOME:"Digite o motivo...",
},
DESCRIP_DATOS:{
  NOME:"Dados referidos por:*",
},
HDA:{
  NOME:"História da Doença Atual (H.D.A)",
}, 
HRDA:{
  NOME:" Antecedentes Remotos de Enfermedad Actual (A.R.E.A):",
}, 
CONDUCTA:{
  NOME:"Conduta:",
}, 
INTERVENCION:{
  NOME:" Antecedentes de Intervenções cirurgicas:",
}, 
EXAMENES:{
  NOME:" Exames Fisicos:",
}, 


// TRIAJE
PRESSAO:{
  NOME:"Pressão Arterial(mmHg)",
},
SATURACAO:{
  NOME:"Saturação(SpO2)",
},
FRE:{
  NOME:"Frequencia Cardiaca(bpm)",
},
RESPIRATORIA:{
  NOME:"Frequencia Respiratoria(bpm)",
},
URGENCIA:{
  NOME:"Grau Urgencia:*",
},

// RECETA
ADMIN:{
  NOME:"Via de Administração:*",
},
INSTRU:{
  NOME:"Instruções:*",
},
// DIAGNOSTICO

CREACION:{
  NOME:"Criar",
},

DIAG:{
  NOME:"Nome do Diagnostico",
},
DESCRIBIR:{
  NOME:"Descricao",
},
ESCRIBIR:{
  NOME:"Descrição",
},

// SALIDA DE PRODUCTO

SALIDA:{
  NOME:"Saida de Produto",
},

DADOSPRO:{
  NOME:"Dados do Produtos",
},

TIPOPRODUTO:{
  NOME:"Tipo de Produto",
},
FRACCION:{
  NOME:"Fração Filial:*",
},
QUANTIDADE:{
  NOME:"Quantidade Filial:*",
},
FRACAO:{
  NOME:"Fração Geral:*",
},

// MODAL TRANSFERENCIA
TRANSFERENCIA:{
  TOTAL:"Quantidade total de itens transferidos"
},
DATATRANSF:{
  NOME:"Data transferência:*",
},
CANTI:{
  NOME:"Quantidade:*",
},
AFRACCION:{
  NOME:"Fração:*",
},



}; 


