import Vue from "vue";
import Vuex from "vuex";

import auth from "./auth.module";
import htmlClass from "./htmlclass.module";
import config from "./config.module";
import breadcrumbs from "./breadcrumbs.module";
import profile from "./profile.module";



////modulos sistema
import sistema from "./vuex/modulos/sistema";
import configFuncionario from "./vuex/modulos/configFuncionarios";
import permissoes from "./vuex/modulos/permissoes"
import configEmpresa from "./vuex/modulos/configEmpresa"
import configUsuarios from "./vuex/modulos/configUsuarios"
import financeiro from "./vuex/modulos/financeiro/financeiro"
import finanOrcamentoAnual from "./vuex/modulos/financeiro/finan0rcamentoAnual"
import atendimento from "./vuex/modulos/atendimento"
import ocorrencia from "./vuex/modulos/ocorrencia"
import ead from "./vuex/modulos/ead/ead"
import produto from "./vuex/modulos/produto"
import relatorios from "./vuex/modulos/relatorios"
import laboratorio from "./vuex/modulos/laboratorio"
import substancia from "./vuex/modulos/substancia"
import tipoClasse from "./vuex/modulos/tipoClasse"
import configEnderecos from "./vuex/modulos/configEnderecos";

Vue.use(Vuex);

export default new Vuex.Store({
    modules: {
        auth,
        htmlClass,
        config,
        breadcrumbs,
        profile,
        sistema,
        configFuncionario,
        permissoes,  
        configEmpresa,
        financeiro,
        finanOrcamentoAnual,
        configUsuarios,
        ead,
        atendimento,
        ocorrencia,
        produto,
        laboratorio,
        substancia,
        tipoClasse,
        relatorios,
        configEnderecos
    }
});