import ApiService from "@/core/services/api.service";
import JwtService from "@/core/services/jwt.service";

export const SET_LISTA_PRODUTOS = "setListprodutos"
export const SET_MESSAGE_ALERT = 'setMensagem'
export const SET_MESSAGE_ALERT2 = 'setMensagem2'
export const SET_LISTA_TIPOS_PRODUTO = "setListTiposProduto"
export const SET_LISTA_PRODUTO_TIPO_CLASSE = 'setListPodutoTipoClasse'
export const SET_LISTA_PRODUTO_SUBSTANCIA = 'setListPodutoSubstancia'
export const SET_PRODUTO_TIPO_CLASSE = 'setPodutoTipoClasse'
export const SET_PRODUTO_SUBSTANCIA = 'setPodutoSubstancia'
export const SET_PRODUTO = 'setProduto'
export const SET_LISTA_PRODUTO_ESTOQUE = 'setListProdutoEstoque'
export const SET_PRODUTO_ESTOQUE = 'setProdutoEstoque'
export const SET_SAIDA_PRODUTO = 'setSaidaProduto'
export const SET_LISTA_SAIDA_PRODUTOS = 'setListaSaidaProdutos'
export const SET_LISTA_ESTOQUE_PRODUTO = 'setListaeEstoqueProdutos'
export const SET_LISTA_TRANSFERENCIAS = 'setListaTrensferencias'
export const SET_TOTAL_EMPRESA = "SET_TOTAL_EMPRESA"
const actions = {

    //#region  produto
    async create_produto(context, value) {
        let message = await ApiService.post('produto', value)
            .then(response => ({ tipo: 'success', message: response.data }))
            .catch((error) => ({ tipo: 'error', message: error.response.data }))
        context.commit(SET_MESSAGE_ALERT, message)
    },
    async update_produto(context, value) {
        let data = await ApiService.put('produto/atualizar', value)
            .then(response => ({ tipo: 'success', message: response.data }))
            .catch((error) => ({ tipo: 'error', message: error.response.data }))
        context.commit(SET_MESSAGE_ALERT, data)
        context.commit(SET_PRODUTO, data.message.conteudo)

    },
    async excluir_produto(context, value) {
        let data = await ApiService.get('produto/delete/' + value)
            .then(response => ({ tipo: 'success', message: response.data }))
            .catch((error) => ({ tipo: 'error', message: error.response.data }))
        console.log(data)
        context.commit(SET_MESSAGE_ALERT, data)
        context.commit(SET_PRODUTO, data.message.conteudo)

    },

    async listar_produtos(context, params) {
        await ApiService.get('produto')
            .then(response => context.commit(SET_LISTA_PRODUTOS, response.data))
            .catch((error) => context.commit(SET_MESSAGE_ALERT, {
                tipo: 'error', message: error.response.data
            }))
    },
    async listar_produtos_filtro(context, filtros) {
        await ApiService.post('produto/search', filtros)
            .then(response => context.commit(SET_LISTA_PRODUTOS, response.data))
            .catch((error) => context.commit(SET_MESSAGE_ALERT, {
                tipo: 'error', message: error.response.data
            }))
    },
    async listar_produtos_tipo(context, params) {
        await ApiService.get('produto/' + params)
            .then(response => context.commit(SET_LISTA_PRODUTOS, response.data))
            .catch((error) => context.commit(SET_MESSAGE_ALERT, {
                tipo: 'error', message: error.response.data
            }))
    },
    async listar_produto(context, params) {
        await ApiService.get('produto/' + params)
            .then(response => context.commit(SET_PRODUTO, response.data))
            .catch((error) => context.commit(SET_MESSAGE_ALERT, {
                tipo: 'error', message: error.response.data
            }))
    },
    async listar_produto_ativo(context) {
        await ApiService.get('produto/ativos')
            .then(response => context.commit(SET_LISTA_PRODUTOS, response.data))
            .catch((error) => context.commit(SET_MESSAGE_ALERT, {
                tipo: 'error', message: error.response.data
            }))
    },

    async listar_produto_estoque_filial(context, filtros) {
        await ApiService.post('produto/listEstoqueFilal', filtros)
            .then(response => context.commit(SET_LISTA_PRODUTOS, response.data))
            .catch((error) => context.commit(SET_MESSAGE_ALERT, {
                tipo: 'error', message: error.response.data
            }))
    },


    //#endregion

    //#region  tipo Produto
    async create_tipo_produto(context, value) {
        let message = await ApiService.post('tipo_produto', value)
            .then(response => ({ tipo: 'success', message: response.data }))
            .catch((error) => ({ tipo: 'error', message: error.response.data }))
        context.commit(SET_MESSAGE_ALERT, message)
    },
    async update_tipo_produto(context, value) {
        let message = await ApiService.put('tipo_produto/atualizar', value)
            .then(response => ({ tipo: 'success', message: response.data }))
            .catch((error) => ({ tipo: 'error', message: error.response.data }))
        context.commit(SET_MESSAGE_ALERT, message)
    },
    async listar_tipos_produto(context, params) {
        await ApiService.get('tipo_produto')
            .then(response => context.commit(SET_LISTA_TIPOS_PRODUTO, response.data))
            .catch((error) => context.commit(SET_MESSAGE_ALERT, {
                tipo: 'error', message: error.response.data
            }))
    },
    async pesquisar_tipos_produto(context, params) {
        await ApiService.get('tipo_produto/pesquisar/' + params)
            .then(response => context.commit(SET_LISTA_TIPOS_PRODUTO, response.data))
            .catch((error) => context.commit(SET_MESSAGE_ALERT, {
                tipo: 'error', message: error.response.data
            }))
    },
    //#endregion

    //#region  vinculos
    async setProdutoEstoque(context, value) {
        let data = await ApiService.post('estoque_produto', value)
            .then(response => ({ tipo: 'success', message: response.data }))
            .catch((error) => ({ tipo: 'error', message: error.response.data }))
        context.commit(SET_MESSAGE_ALERT, data)
        if (data.message.conteudo.id)
            context.commit(SET_PRODUTO_ESTOQUE, data.message.conteudo.estoque)
        context.commit(SET_PRODUTO, data.message.conteudo.produto)
    },
    async deleteEstoque(context, value) {
        let data = await ApiService.put('estoque_produto/delete/' + value)
            .then(response => ({ tipo: 'success', message: response.data }))
            .catch((error) => ({ tipo: 'error', message: error.response.data }))
        context.commit(SET_MESSAGE_ALERT, data)
        if (data.message.conteudo.id)
            context.commit(SET_PRODUTO_ESTOQUE, data.message.conteudo.estoque)
        context.commit(SET_PRODUTO, data.message.conteudo.produto)
    },

    async vinculo_substancia(context, value) {
        let data = await ApiService.post('produto_substancia', value)
            .then(response => ({ tipo: 'success', message: response.data }))
            .catch((error) => ({ tipo: 'warning', message: error.response.data }))
        context.commit(SET_MESSAGE_ALERT, data)
        if (data.message.conteudo.id)
            context.commit(SET_PRODUTO_SUBSTANCIA, data.message.conteudo)
    },

    async desvincular_substancia(context, value) {
        let data = await ApiService.post('produto_substancia/desvincular', value)
            .then(response => ({ tipo: 'success', message: response.data }))
            .catch((error) => ({ tipo: 'warning', message: error.response.data }))
        context.commit(SET_MESSAGE_ALERT, data)
        // if(data.message.conteudo.id)
        // context.commit(DEL_PROD;;UTO_SUBSTANCIA,data.message.conteudo)
        // this.state.lista_substancias_produto = []
    },
    async vinculo_tipo_classe(context, value) {
        let data = await ApiService.post('produto_tipo_classe', value)
            .then(response => ({ tipo: 'success', message: response.data }))
            .catch((error) => ({ tipo: 'warning', message: error.response.data }))

        context.commit(SET_MESSAGE_ALERT, data)
        if (data.message.conteudo.id)
            context.commit(SET_PRODUTO_TIPO_CLASSE, data.message.conteudo)

    },
    async desvincular_classe(context, value) {
        let data = await ApiService.post('produto_tipo_classe/desvincular', value)
            .then(response => ({ tipo: 'success', message: response.data }))
            .catch((error) => ({ tipo: 'warning', message: error.response.data }))
        context.commit(SET_MESSAGE_ALERT, data)
        // if(data.message.conteudo.id)
        // context.commit(DEL_PROD;;UTO_SUBSTANCIA,data.message.conteudo)
        // this.state.lista_substancias_produto = []
    },
    async listar_substancias_produto(context, value) {
        await ApiService.get('produto_substancia/produto/' + value)
            .then(response => context.commit(SET_LISTA_PRODUTO_SUBSTANCIA, response.data))
            .catch((error) => context.commit(SET_MESSAGE_ALERT, {
                tipo: 'error', message: error.response.data
            }))
    },
    async listar_tipo_classe_produto(context, value) {
        await ApiService.get('produto_tipo_classe/produto/' + value)
            .then(response => context.commit(SET_LISTA_PRODUTO_TIPO_CLASSE, response.data))
            .catch((error) => context.commit(SET_MESSAGE_ALERT, {
                tipo: 'error', message: error.response.data
            }))
    },
    async listar_produto_estoque_id(context, value) {
        await ApiService.get('estoque_produto/produto/' + value)
            .then(response => context.commit(SET_LISTA_PRODUTO_ESTOQUE, response.data))
            .catch((error) => context.commit(SET_MESSAGE_ALERT, {
                tipo: 'error', message: error.response.data.message
            }))
    },
    async listar_produto_estoque(context) {
        await ApiService.get('estoque_produto')
            .then(response => context.commit(SET_LISTA_PRODUTO_ESTOQUE, response.data))
            .catch((error) => context.commit(SET_MESSAGE_ALERT, {
                tipo: 'error', message: error.response.data
            }))
    },
    async listar_estoques_disponiveis(context, value) {
        await ApiService.get('estoque_produto/getDisponiveis/' + value)
            .then(response => context.commit(SET_LISTA_PRODUTO_ESTOQUE, response.data))
            .catch((error) => context.commit(SET_MESSAGE_ALERT, {
                tipo: 'error', message: error.response.data
            }))
    },
    async pesquisar_estoques_ativos(context, params) {
        await ApiService.post('relatorio/estoques', params)
            .then(response => context.commit(SET_LISTA_ESTOQUE_PRODUTO, response.data))
            .catch((error) => context.commit(SET_MESSAGE_ALERT, {
                tipo: 'error', message: error.response.data
            }))
    },
    async pesquisar_estoque_por_lotes(context, params) {
        await ApiService.get('estoque_produto/lote/' + params.texto + '/' + params.tipo)
            .then(response => context.commit(SET_LISTA_ESTOQUE_PRODUTO, response.data))
            .catch((error) => context.commit(SET_MESSAGE_ALERT, {
                tipo: 'error', message: error.response.data
            }))
    },
    async pesquisar_estoques_ativos_excel(context, value) {
        // await ApiService.post('relatorio/estoques/excel', value)
        // .then(response => context.commit(SET_LISTA_ESTOQUE_PRODUTO, response.data))
        // .catch((error) => context.commit(SET_MESSAGE_ALERT, {
        //     tipo: 'error', message: error.response.data
        // }))
        await ApiService.post('relatorio/estoques/excel', value, {
            responseType: 'blob'
        }).then(response => {
            let fileUrl = window.URL.createObjectURL(response.data);
            let fileLink = document.createElement('a');

            fileLink.href = fileUrl;
            fileLink.setAttribute('download', 'estoques_ativos.xls');
            document.body.appendChild(fileLink)

            fileLink.click();
        }).catch((error) => context.commit(SET_MESSAGE_ALERT, {
            tipo: 'error', message: error.response.data
        }))
    },

    //#endregion


    //#region   SAIDA PRODUTOS
    async create_saida_produto(context, value) {
        let message = await ApiService.post('saida_produto', value)
            .then(response => ({ tipo: 'success', message: response.data }))
            .catch((error) => ({ tipo: 'error', message: error.response.data }))
        context.commit(SET_MESSAGE_ALERT, message)

    },

    async update_saida_produto(context, dados) {
        let data = await ApiService.put('saida_produto/atualizar/' + dados.id, dados.form)
            .then(response => ({ tipo: 'success', message: response.data }))
            .catch((error) => ({ tipo: 'error', message: error.response.data }))
        context.commit(SET_MESSAGE_ALERT, data)
    },

    async listar_saida_produtos(context, params) {
        await ApiService.post('saida_produto/relatorio', params)

            .then(response => context.commit(SET_LISTA_SAIDA_PRODUTOS, response.data))
            .catch((error) => context.commit(SET_MESSAGE_ALERT, {
                tipo: 'error', message: error.response.data
            }))

    },

    async listar_saida_produtos_atendimento(context, params) {
        await ApiService.get('saida_produto/atendimento/' + params)
            .then(response => context.commit(SET_LISTA_SAIDA_PRODUTOS, response.data))
            .catch((error) => context.commit(SET_MESSAGE_ALERT, {
                tipo: 'error', message: error.response.data
            }))
    },

    async get_saida_produto(context, params) {
        await ApiService.get('saida_produto/' + params)
            .then(response => context.commit(SET_LISTA_SAIDA_PRODUTOS, response.data))
            .catch((error) => context.commit(SET_MESSAGE_ALERT, {
                tipo: 'error', message: error.response.data
            }))
    },

    async desfazer_saida_produto(context, params) {
        await ApiService.delete('saida_produto/' + params)
            .then(response => ({ tipo: 'success', message: response.data }))
            .catch((error) => context.commit(SET_MESSAGE_ALERT, {
                tipo: 'error', message: error.response.data
            }))
    },

    //#endregion
    async pesquisar_produto(context, data) {
        await ApiService.post('produto/buscar', data)
            .then(response => context.commit(SET_LISTA_PRODUTOS, response.data))
        console.log(response.data)
            .catch((error) => context.commit(SET_MESSAGE_ALERT, {
                tipo: 'error', message: error.response.data
            }))
    },
    //#region TRANSFERENCIA DE ESTOQUES
    async transferir_estoque(context, data) {
        let da = await ApiService.post('estoque_produto/transferir', data)
            .then(response => ({ tipo: 'success', message: response.data }))
            .catch((error) => ({ tipo: 'error', message: error.response.data }))
        context.commit(SET_MESSAGE_ALERT, da)
    },
    async receber_estoque(context, data) {
        let da = await ApiService.post('estoque_produto/receber', data)
            .then(response => ({ tipo: 'success', message: response.data }))
            .catch((error) => ({ tipo: 'error', message: error.response.data }))
        context.commit(SET_MESSAGE_ALERT, da)
    },

    async listar_transferencias(context, data) {
        await ApiService.post('transferencias', data)
            .then(response => context.commit(SET_LISTA_TRANSFERENCIAS, response.data))
            .catch((error) => context.commit(SET_MESSAGE_ALERT, {
                tipo: 'error', message: error.response.data
            }))
    },

    async excel_transferencias(context, data) {
        ApiService.post("transferencias/excel", data, {
            responseType: 'blob'
        }).then(response => {
            let fileUrl = window.URL.createObjectURL(response.data);
            let fileLink = document.createElement('a');

            fileLink.href = fileUrl;
            fileLink.setAttribute('download', 'relatorio_transferencias.xls');
            document.body.appendChild(fileLink)

            fileLink.click();
        }).catch((error) => context.commit(SET_MESSAGE_ALERT, {
            tipo: 'error', message: error.response.data
        }))
    },
    async pdf_transferencias(context, data) {
        ApiService.post("transferencias/pdf", data, {
            responseType: 'blob'
        }).then(response => {
            let fileUrl = window.URL.createObjectURL(response.data);
            let fileLink = document.createElement('a');

            fileLink.href = fileUrl;
            fileLink.setAttribute('download', 'relatorio_transferencias.pdf');
            document.body.appendChild(fileLink)

            fileLink.click();
        }).catch((error) => context.commit(SET_MESSAGE_ALERT, {
            tipo: 'error', message: error.response.data
        }))
    },

    //#endregion
    async atualizar(context, data) {
        await ApiService.post('estoque_produto/atualizar', data)
            .then(response => ({ tipo: 'success', message: response.data }))
            .catch((error) => context.commit(SET_MESSAGE_ALERT, {
                tipo: 'error', message: error.response.data
            }))
    },

    async regularizar_estoque(context, data) {
        let da = await ApiService.post('estoque_produto/regularizar', data)
            .then(response => ({ tipo: 'success', message: response.data }))
            .catch((error) => ({ tipo: 'error', message: error.response.data }))
            context.commit(SET_MESSAGE_ALERT, da)
    },

    async relatorio(context, params) {
        await ApiService.post('relatorio', params)
            .then(response => context.commit(SET_LISTA_PRODUTOS, response.data))

            .catch((error) => context.commit(SET_MESSAGE_ALERT, {
                tipo: 'error', message: error.response.data
            }))
    },

    async excel(context, params) {
        ApiService.post("relatorio/excel", params, {
            responseType: 'blob'
        }).then(response => {
            let fileUrl = window.URL.createObjectURL(response.data);
            let fileLink = document.createElement('a');

            fileLink.href = fileUrl;
            fileLink.setAttribute('download', 'relatorio_produtos.xls');
            document.body.appendChild(fileLink)

            fileLink.click();
        }).catch((error) => context.commit(SET_MESSAGE_ALERT, {
            tipo: 'error', message: error.response.data
        }))
    },

    async excel_substancias(context, params) {
        await ApiService.post('relatorio/excel_substancias', params, {
            responseType: 'blob'
        }).then(response => {
            let fileUrl = window.URL.createObjectURL(response.data);
            let fileLink = document.createElement('a');

            fileLink.href = fileUrl;
            fileLink.setAttribute('download', 'relatorio_produtos.xls');
            document.body.appendChild(fileLink)

            fileLink.click();
        }).catch((error) => context.commit(SET_MESSAGE_ALERT, {
            tipo: 'error', message: error.response.data
        }))
    },

    async excel_classes(context, params) {
        await ApiService.post('relatorio/excel_classes', params)
            .then(response => context.commit(SET_LISTA_PRODUTOS, response.data))
        console.log(response.data)
            .catch((error) => context.commit(SET_MESSAGE_ALERT, {
                tipo: 'error', message: error.response.data
            }))
        // await ApiService.post('relatorio/excel_classes', params,{
        //     responseType: 'blob'
        // }).then(response => {
        //     let fileUrl = window.URL.createObjectURL(response.data);
        //     let fileLink = document.createElement('a');

        //     fileLink.href = fileUrl;
        //     fileLink.setAttribute('download', 'relatorio_produtos.xls');
        //     document.body.appendChild(fileLink)

        //     fileLink.click();
        // }).catch((error) => context.commit(SET_MESSAGE_ALERT, {
        //     tipo: 'error', message: error.response.data
        // }))
    },

    async excel_cadastros(context, params) {
        await ApiService.post('relatorio/excel_cadastros', params, {
            responseType: 'blob'
        }).then(response => {
            let fileUrl = window.URL.createObjectURL(response.data);
            let fileLink = document.createElement('a');

            fileLink.href = fileUrl;
            fileLink.setAttribute('download', 'cadastros_produtos.xls');
            document.body.appendChild(fileLink)

            fileLink.click();
        }).catch((error) => context.commit(SET_MESSAGE_ALERT, {
            tipo: 'error', message: error.response.data
        }))
    },

    async desfazer_transferencia(context, id) {
        console.log("aqui")
        let resposta = await ApiService.put('estoque_produto/desfazer_transferencia/' + id)
            .then(response => ({ tipo: 'success', message: response.data }))
            .catch((error) => ({ tipo: 'error', message: error.response.data }))
        context.commit(SET_MESSAGE_ALERT2, resposta)
    },

    async get_estoques_empresa(context, params) {
        await ApiService.get('produto/estoque_empresa/' + params.produto_id + '/' + params.empresa_id)
            .then(response => context.commit(SET_TOTAL_EMPRESA, response.data))
            .catch((error) => context.commit(SET_MESSAGE_ALERT, {
                tipo: 'error', message: error.response.data
            }))
    },


};

const getters = {};

const mutations = {
    [SET_PRODUTO_ESTOQUE](state, value) {
        console.log(value)
        state.lista_produtos_estoque.push(value)
        console.log(state.lista_produtos_estoque)
    },
    [SET_LISTA_PRODUTO_ESTOQUE](state, value) {
        state.lista_produtos_estoque = value
    },
    [SET_TOTAL_EMPRESA](state, value) {
        state.total_empresa = value
    },
    [SET_LISTA_PRODUTOS](state, value) {
        state.lista_produtos = value
    },
    [SET_LISTA_TIPOS_PRODUTO](state, value) {
        state.lista_tipos_produto = value
    },
    [SET_LISTA_PRODUTO_TIPO_CLASSE](state, value) {
        state.listar_tipos_classe_produto = value
    },
    [SET_LISTA_PRODUTO_SUBSTANCIA](state, value) {
        state.lista_substancias_produto = value
    },
    [SET_PRODUTO_TIPO_CLASSE](state, value) {
        state.listar_tipos_classe_produto.push(value);
    },

    [SET_PRODUTO_SUBSTANCIA](state, value) {
        // console.log
        state.lista_substancias_produto.push(value);
        // console.log(state.lista_substancias_produto)
    },

    [SET_PRODUTO](state, value) {
        state.produto = value
    },

    [SET_LISTA_TRANSFERENCIAS](state, value) {
        state.lista_transferencias = value
    },

    [SET_LISTA_SAIDA_PRODUTOS](state, value) {
        state.lista_saida_produtos = value
    },

    [SET_LISTA_ESTOQUE_PRODUTO](state, value) {
        state.lista_estoque_produtos = value
    },


    [SET_MESSAGE_ALERT](state, value) {

        console.log(value)
        let customMessage;
        if (typeof value.message === "object") {

            customMessage = value.message.msg;
        }

        if (value.message.msg === "erro") {
            customMessage = value.message.conteudo;
            console.log(customMessage)
            state.mensagem_alert = {
                tipo: "error",
                message: customMessage
            };
        } else {
            state.mensagem_alert = {
                tipo: value.tipo,
                message: customMessage ? customMessage : value.message,
            };
        }
    },

    [SET_MESSAGE_ALERT2](state, value) {
        console.log(value)
        state.mensagem_alert = {
            tipo: value.tipo,
            message: value.message.msg,
        };

    },

};

const state = {

    lista_produtos: [],
    lista_tipos_produto: [],
    mensagem_alert: "",
    listar_tipos_classe_produto: [],
    lista_substancias_produto: [],
    lista_produtos_estoque: [],
    produto: [],
    lista_estoque_produtos: [],
    lista_saida_produtos: [],
    lista_transferencias: [],
    total_empresa: []


};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters
};