import ApiService from "@/core/services/api.service";
import JwtService from "@/core/services/jwt.service";

export const SET_LISTA_LABORATORIOS = "setListlaboratorios"
export const SET_MESSAGE_ALERT = 'setMensagem'

const actions = {

    //#region  laboratorio
    async create_laboratorio(context, value) {
        let message = await ApiService.post('laboratorio', value)
            .then(response => ({tipo: 'success', message: response.data}))
            .catch((error) => ({tipo: 'error', message: error.response.data}))
        context.commit(SET_MESSAGE_ALERT, message)
    },
    async update_laboratorio(context, value) {
        let message = await ApiService.put('laboratorio/atualizar', value)
            .then(response => ({tipo: 'success', message: response.data}))
            .catch((error) => ({tipo: 'error', message: error.response.data}))
        context.commit(SET_MESSAGE_ALERT, message)
    },
    async listar_laboratorios(context, params) {
        await ApiService.get('laboratorio')
            .then(response => context.commit(SET_LISTA_LABORATORIOS, response.data))
            .catch((error) => context.commit(SET_MESSAGE_ALERT, {
                tipo: 'error', message: error.response.data
            }))
    },
    async pesquisar_laboratorios(context, params) {
        await ApiService.get('laboratorio/filtro/'+ params)
            .then(response => context.commit(SET_LISTA_LABORATORIOS, response.data))
            .catch((error) => context.commit(SET_MESSAGE_ALERT, {
                tipo: 'error', message: error.response.data
            }))
    },

    //#endregion

  
    

};

const getters = {};

const mutations = {
    [SET_LISTA_LABORATORIOS](state, value) {
        state.lista_laboratorios = value
    },
  

    [SET_MESSAGE_ALERT](state, value) {
        console.log(value)
        let customMessage;
        if (typeof value.message === "object") {
          customMessage = value.message.msg;
        }
        state.mensagem_alert = {
          tipo: value.tipo,
          message: customMessage ? customMessage : value.message,
        };
      },
};

const state = {
    lista_laboratorios: [],
    mensagem_alert: "",


};

export default {
    namespaced:true,
    state,
    actions,
    mutations,
    getters
};