import ApiService from "@/core/services/api.service";
import JwtService from "@/core/services/jwt.service";

export const SET_LISTA_TIPO_CLASSE = "setListipoClasses"
export const SET_MESSAGE_ALERT = 'setMensagem'

const actions = {

    //#region  tipo_classe
    async create_tipo_classe(context, value) {
        let message = await ApiService.post('tipo_classe', value)
            .then(response => ({tipo: 'success', message: response.data}))
            .catch((error) => ({tipo: 'error', message: error.response.data}))
        context.commit(SET_MESSAGE_ALERT, message)
    },
    
    async update_tipo_classe(context, value) {
        let message = await ApiService.put('tipo_classe/atualizar', value)
            .then(response => ({tipo: 'success', message: response.data}))
            .catch((error) => ({tipo: 'error', message: error.response.data}))
        context.commit(SET_MESSAGE_ALERT, message)
    },
    async listar_tipos_classe(context, params) {
        await ApiService.get('tipo_classe')
            .then(response => context.commit(SET_LISTA_TIPO_CLASSE, response.data))
            .catch((error) => context.commit(SET_MESSAGE_ALERT, {
                tipo: 'error', message: error.response.data
            }))
    },

    //#endregion

  
    

};

const getters = {};

const mutations = {
    [SET_LISTA_TIPO_CLASSE](state, value) {
        state.lista_tipos_classe = value
    },
  

    [SET_MESSAGE_ALERT](state, value) {
        console.log(value)
        let customMessage;
        if (typeof value.message === "object") {
          customMessage = value.message.msg;
        }
        state.mensagem_alert = {
          tipo: value.tipo,
          message: customMessage ? customMessage : value.message,
        };
      },
};

const state = {
    lista_tipos_classe: [],
    mensagem_alert: "",


};

export default {
    namespaced:true,
    state,
    actions,
    mutations,
    getters
};