import ApiService from "@/core/services/api.service";
import JwtService from "@/core/services/jwt.service";


export const SET_MESSAGE_ALERT = 'setMensagem'
export const SET_DADOS_TRANSFERENCIAS = 'setDadosTransferencias'
export const SET_NUM_ATENDIMENTOS = 'setNumAtendimentos'
export const SET_NUM_ATENDIMENTOS_FILIAL = 'setNumAtendimentosFilial'
export const SET_NUM_SAIDAS = 'setNumSaidas'
export const SET_NUM_SAIDAS_FILIAL = 'setNumSaidasFilial'
export const SET_NUM_ATENDIMENTOS_MES = 'setNumSaidasFilialMes'
export const SET_NUM_ATENDIMENTOS_G = 'setAtendimentos]'
export const SET_NUM_ATENDIMENTOS_DIA = 'setAtendimentosDia'
export const SET_NUM_OCORRENCIAS_FILIAL = "setNumOcorrenciasFilial"



const actions = {
    async excelSaidas(context, params) {

        ApiService.post("saida_produto/excel", params, {
            responseType: 'blob'
        }).then(response => {
            let fileUrl = window.URL.createObjectURL(response.data);
            let fileLink = document.createElement('a');

            fileLink.href = fileUrl;
            fileLink.setAttribute('download', 'relatorio_produtos.xls');
            document.body.appendChild(fileLink)

            fileLink.click();
        }).catch((error) => context.commit(SET_MESSAGE_ALERT, {
            tipo: 'error', message: error.response.data
        }))



    },

    async get_num_atentimentos(context) {
        await ApiService.get('relatorio/atendimentos/filial')
            .then(response => context.commit(SET_NUM_ATENDIMENTOS_FILIAL, response.data))
            .catch((error) => context.commit(SET_MESSAGE_ALERT, {
                tipo: 'error', message: error.response.data
            }))
    },

    async get_num_ocorrencias(context, value) {
        await ApiService.post('relatorio/atendimentos/ocorrencias/filial', value)
            .then(response => context.commit(SET_NUM_OCORRENCIAS_FILIAL, response.data))
            .catch((error) => context.commit(SET_MESSAGE_ALERT, {
                tipo: 'error', message: error.response.data
            }))
    },


    async num_atendimentos_por_filial(context, value) {
        await ApiService.post('relatorio/atendimentosPorFilial', value)
            .then(response => context.commit(SET_NUM_ATENDIMENTOS, response.data))
            .catch((error) => context.commit(SET_MESSAGE_ALERT, {
                tipo: 'error', message: error.response.data
            }))
    },

    async num_transferencias_por_filial(context, value) {
        await ApiService.post('relatorio/transferencias/filial', value)
            .then(response => context.commit(SET_DADOS_TRANSFERENCIAS, response.data))
            .catch((error) => context.commit(SET_MESSAGE_ALERT, {
                tipo: 'error', message: error.response.data
            }))
    },
    async num_atendimentos_filial_mes(context,value) {
        await ApiService.post('relatorio/atendimentos/filial/mes',value)
            .then(response => context.commit(SET_NUM_ATENDIMENTOS_MES, response.data))
            .catch((error) => context.commit(SET_MESSAGE_ALERT, {
                tipo: 'error', message: error.response.data
            }))
    },
    async num_saidas_por_filial(context, value) {
        await ApiService.post('relatorio/saidas', value)
            .then(response => context.commit(SET_NUM_SAIDAS_FILIAL, response.data))
            .catch((error) => context.commit(SET_MESSAGE_ALERT, {
                tipo: 'error', message: error.response.data
            }))
    },
    // Numero de saidas da filial por classe de medicamento
    async num_saidas_por_tipo(context) {
        await ApiService.get('relatorio/saidas/classe')
            .then(response => context.commit(SET_NUM_SAIDAS, response.data))
            .catch((error) => context.commit(SET_MESSAGE_ALERT, {
                tipo: 'error', message: error.response.data
            }))
    },
    //  Numero de saidas geral por classe de medicamento
    async num_saidas_filial_por_tipo(context) {
        await ApiService.get('relatorio/saidas/classe/filial')
            .then(response => context.commit(SET_NUM_SAIDAS, response.data))
            .catch((error) => context.commit(SET_MESSAGE_ALERT, {
                tipo: 'error', message: error.response.data
            }))
    },
    // retorna numeros de atendimentso geral
    async num_atendimentos_geral(context) {
        await ApiService.get('relatorio/atendimentos')
            .then(response => context.commit(SET_NUM_ATENDIMENTOS_G, response.data))
            .catch((error) => context.commit(SET_MESSAGE_ALERT, {
                tipo: 'error', message: error.response.data
            }))
    },
    // retorna numeros de pacientes
    async num_pacientes(context) {
        await ApiService.get('relatorio/pacientes')
            .then(response => context.commit(SET_NUM_SAIDAS, response.data))
            .catch((error) => context.commit(SET_MESSAGE_ALERT, {
                tipo: 'error', message: error.response.data
            }))
    },
    // retorna numeros de atendimentso do dia
    async get_num_atentimentos_dia(context) {
        await ApiService.get('relatorio/atendimentos/dia')
            .then(response => context.commit(SET_NUM_ATENDIMENTOS_DIA, response.data))
            .catch((error) => context.commit(SET_MESSAGE_ALERT, {
                tipo: 'error', message: error.response.data
            }))
    },
    async excel_saidas_empresa(context) {
        await ApiService.get('saida_produto/relatorio_empresa')
            .then(response => context.commit(SET_NUM_ATENDIMENTOS_DIA, response.data))
            .catch((error) => context.commit(SET_MESSAGE_ALERT, {
                tipo: 'error', message: error.response.data
            }))
    },

};

const getters = {};

const mutations = {
    [SET_MESSAGE_ALERT](state, value) {
        console.log(value)
        let customMessage;
        if (typeof value.message === "object") {

            customMessage = value.message.msg;
        }
        state.mensagem_alert = {
            tipo: value.tipo,
            message: customMessage ? customMessage : value.message,
        };
    },
    [SET_NUM_ATENDIMENTOS](state, value) {
        state.num_atendimentos = value
    },
    [SET_DADOS_TRANSFERENCIAS](state, value) { 
        state.dados_transferencias = value
    },
    [SET_NUM_ATENDIMENTOS_G](state, value) {
        state.num_atendimentos_g = value
    },
    [SET_NUM_ATENDIMENTOS_DIA](state, value) {
        state.num_atendimentos_dia = value
    },
    [SET_NUM_ATENDIMENTOS_FILIAL](state, value) {
        state.num_atendimentos_filial = value
    },
    [SET_NUM_OCORRENCIAS_FILIAL](state, value) {
        state.num_ocorrencias_filial = value
    },
    [SET_NUM_SAIDAS](state, value) {
        state.num_saidas = value
    },
    [SET_NUM_SAIDAS_FILIAL](state, value) {
        state.num_saidas_por_filial = value
    },
    [SET_NUM_ATENDIMENTOS_MES](state, value) {
        state.num_atendimentos_filial_mes = value
    }

};

const state = {
    num_atendimentos: [],
    num_saidas: [],
    num_saidas_por_filial: [],
    num_atendimentos_filial: [],
    num_atendimentos_filial_mes: [],
    num_ocorrencias_filial: [],
    num_atendimentos_g: "",
    num_atendimentos_dia: "",
    dados_transferencias:[]
};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters,

};