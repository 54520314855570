import Vue from "vue";
import VueI18n from "vue-i18n";

// Localisation language list
import { locale as en } from "@/core/config/i18n/en";
import { locale as pt } from "@/core/config/i18n/pt";
import { locale as es } from "@/core/config/i18n/es";
import { locale as jp } from "@/core/config/i18n/jp";
import { locale as de } from "@/core/config/i18n/de";
import { locale as fr } from "@/core/config/i18n/fr";
import { locale as dataTime } from "@/core/config/i18n/dataTime";

Vue.use(VueI18n);

let messages = {};
messages = { ...messages, en, es, jp, de, fr, pt};

// get current selected language
const lang = localStorage.getItem("language") || "es";

// Create VueI18n instance with options
const i18n = new VueI18n({
  locale: lang, // set locale
  messages, // set locale messages
  dataTime
});

export default i18n;
