import ApiService from "@/core/services/api.service";
import JwtService from "@/core/services/jwt.service";

export const SET_LIST_PAISES = "setListPaises";
export const SET_LIST_ESTADOS_NACIONALIDADE = "setListEstadosNacionalidade";
export const SET_LIST_ESTADOS_ENDERECO = "setListEstadosEndereco";
export const SET_LIST_CIDADES_NACIONALIDADE = "setListCIDADESNacionalidade";
export const SET_LIST_CIDADES_ENDERECO = "setListCIDADESEndereco";
export const SET_LIST_BAIRRO_ENDERECO = "setListBairroEndereco";
export const SET_ROLE = "setRole";
export const SET_USER_ROLES = "setUserRole";
export const SET_LIST_ROLES_PERMISSOES = "setListRolesPermissoes"


const actions = {
    async all_paises(context) {
        await ApiService.get('pais')
            .then(response => context.commit(SET_LIST_PAISES, response.data))
            .catch((error) => context.commit('SET_MESSAGE_ALERT', {
                tipo: 'error', message: error.response.data
            }))
    },

    async getEstadosNacionalidade(context, value) {
        await ApiService.get('pais/estados/' + value)
            .then(response => context.commit(SET_LIST_ESTADOS_NACIONALIDADE, response.data))
            .catch((error) => context.commit('SET_MESSAGE_ALERT', {
                tipo: 'error', message: error.response.data
            }))
    },
    async getEstadosEndereco(context, value) {
        await ApiService.get('pais/estados/' + value)
            .then(response => context.commit(SET_LIST_ESTADOS_ENDERECO, response.data))
            .catch((error) => context.commit('SET_MESSAGE_ALERT', {
                tipo: 'error', message: error.response.data
            }))
    },
    async getCidadesNacionalidade(context, value) {
        await ApiService.get('pais/estado/cidade/' + value)
            .then(response => context.commit(SET_LIST_CIDADES_NACIONALIDADE, response.data))
            .catch((error) => context.commit('SET_MESSAGE_ALERT', {
                tipo: 'error', message: error.response.data
            }))
    },
    async getCidadesEndereco(context, value) {
        await ApiService.get('pais/estado/cidade/' + value)
            .then(response => context.commit(SET_LIST_CIDADES_ENDERECO, response.data))
            .catch((error) => context.commit('SET_MESSAGE_ALERT', {
                tipo: 'error', message: error.response.data
            }))
    },

    async getBairrosEndereco(context, value) {
        await ApiService.get('pais/estado/cidade/bairro/' + value)
            .then(response => context.commit(SET_LIST_BAIRRO_ENDERECO, response.data))
            .catch((error) => context.commit('SET_MESSAGE_ALERT', {
                tipo: 'error', message: error.response.data
            }))
    },
    
    async getBairros(context,value) {
        await ApiService.post('pais/bairros',value)
            .then(response => context.commit(SET_LIST_BAIRRO_ENDERECO, response.data))
            .catch((error) => context.commit('SET_MESSAGE_ALERT', {
                tipo: 'error', message: error.response.data
            }))
    },
};

const getters = {};

const mutations = {
    [SET_LIST_PAISES](state, value) {
        state.lista_pais = value
    },
    [SET_LIST_ESTADOS_NACIONALIDADE](state, value) {
        state.lista_estados_nacionalidade = value
    },
    [SET_LIST_ESTADOS_ENDERECO](state, value) {
        state.lista_estados_endereco = value
    },
    [SET_LIST_CIDADES_NACIONALIDADE](state, value) {
        state.lista_cidades_nacionalidade = value
    },
    [SET_LIST_CIDADES_ENDERECO](state, value) {
        state.lista_cidades_endereco = value
    },
    [SET_LIST_BAIRRO_ENDERECO](state, value) {
        state.lista_bairros_endereco = value
    },
};

const state = {
    lista_pais: [],
    lista_estados_nacionalidade: [],
    lista_estados_endereco: [],
    lista_cidades_nacionalidade:[],
    lista_cidades_endereco:[],
    lista_roles_permissoes: [],
    lista_bairros_endereco:[],
    role: {},
    user_roles: []
};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters
};