import ApiService from "@/core/services/api.service";
import FilialService from "@/core/services/filial.service";
import UsuarioService from "@/core/services/usuario.service";
import JwtService from "@/core/services/jwt.service";

export const SET_LISTA_PAIS = "setListPais";
const actions = {
    async PesquisarAluno(context, value) {
        ApiService.setHeader();
        await ApiService.post('usuario/buscar', value)
            .then(response => context.commit('SET_LISTA_ALUNO', response.data.data))
            .catch((error) => {
                console.log(error)
            })
    },
    async setFilial(context, value) {
        await FilialService.setFilialId(value)
        context.commit('SET_ID_FILIAL_SELECIONADA', value)
        ApiService.setHeaderFilial();
    },
    async setEmpresa(context,value){
        await FilialService.setEmpresaId(value)
    },

    setFilialGuardada(context) {
        let filialSelected = FilialService.getFilialId();
        context.dispatch('listar_permissoes_sede', filialSelected)
    },
    async listar_permissoes_sede(context, filialId) {
        filialId = parseInt(filialId)
        let filiais = FilialService.getFiliais()
        if (!filialId || !filiais[filialId]) {
            context.commit('SET_LISTA_PERMISSOES', "")
            return
        }
        let listaPermissoes = {}
        filiais[filialId].permissoes.forEach((item) => {
            listaPermissoes[item] = item
            
        })
        let listaRoles = {}
        filiais[filialId].roles.forEach((item) => {
            listaRoles[item] = item
        })
        console.log(listaRoles)
        console.log(listaPermissoes)
        context.commit('SET_LISTA_PERMISSOES', listaPermissoes)
        context.commit('SET_LISTA_ROLES', listaRoles)
    },
    async listar_filiais_autorizadas(context) {
        let filiais = FilialService.getFiliais()
        context.commit('SET_LISTA_FILIAIS_AUTORIZADAS', filiais)
    },
    async listar_pais(context) {
        ApiService.setHeader();
        await ApiService.get("pais")
            .then((response) => context.commit(SET_LISTA_PAIS, response.data))
            .catch((error) =>
                context.commit("SET_MESSAGE_ALERT", {
                    tipo: "error",
                    message: error.response.data,
                })
            );
    },
};

const getters = {};

const mutations = {
    [SET_LISTA_PAIS](state, value) {
        state.lista_pais = value;
    },

    SET_LISTA_ALUNO(state, value) {
        state.lista_aluno = value
    },
    SET_LISTA_PERMISSOES(state, value) {
        state.lista_permissoes_filial = value
    },
    SET_LISTA_ROLES(state, value) {
        state.lista_roles = value
    },
    SET_LISTA_FILIAIS_AUTORIZADAS(state, value) {
        state.lista_filiais_autorizadas = value
    },
    SET_ID_FILIAL_SELECIONADA(state, value) {
        state.id_filial_selecionada = value
    },

};

const state = {
    lista_aluno: {},
    lista_permissoes_filial: {},
    lista_roles: {},
    lista_filiais_autorizadas: [],
    lista_pais: [],
    id_filial_selecionada: FilialService.getFilialId(),
};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters
};