import ApiService from "@/core/services/api.service";
import JwtService from "@/core/services/jwt.service";

export const SET_LISTA_EMPRESAS = "setListEmpresa";
export const SET_LISTA_FILIALS = "setListFilial";
export const SET_LISTA_SEMESTRES = "setListSemestres";
export const SET_LISTA_ANOS = "setListAnos";
export const SET_LISTA_SEMESTRES_ANUAL = "setListSemestreAnual";
export const SET_LISTA_CONVOCATORIAS = "setListConvocatorias";
export const SET_LISTA_SEMESTRE_OFERTA_DISCIPLINA = "setListsemestreofertadisciplina";
export const SET_LISTA_SEMESTRE_LETIVO = "setListsemestreOfertaLetivo";
export const SET_LISTA_SALA = "setListSala";
export const SET_LISTA_PRODUTO = "setListProduto";
export const SET_LISTA_LOCAL = "setListLocal";
export const SET_LISTA_SETOR = "setListSetor";
export const SET_LISTA_PERFIL = "setListPerfil";
export const SET_LISTA_ESPECIALIDADE = "setListEspecialidade";
export const SET_LISTA_DIAGNOSTICO = "setListDiagnostico";
export const SET_LISTA_VINCULO_ESPECIALIDADE = "setListVinculoEspecialidade";


const actions = {
  async create_filial(context, value) {
    ApiService.setHeader();
    await ApiService.post("filial/criar", value)
      .then((response) =>
        context.commit("SET_MESSAGE_ALERT", {
          tipo: "success",
          message: response.data,
        })
      )
      .catch((error) =>
        context.commit("SET_MESSAGE_ALERT", {
          tipo: "error",
          message: error.response.data,
        })
      );
  },
  async update_filial(context, value) {
    ApiService.setHeader();
    await ApiService.put("filial/atualizar/" + value.id, value)
      .then((response) =>
        context.commit("SET_MESSAGE_ALERT", {
          tipo: "success",
          message: response.data,
        })
      )
      .catch((error) =>
        context.commit("SET_MESSAGE_ALERT", {
          tipo: "error",
          message: error.response.data,
        })
      );
  },
  async delete_filial(context, value) {
    ApiService.setHeader();

    await ApiService.put("filial/bloquea/" + value.id)
      .then((response) =>
        context.commit("SET_MESSAGE_ALERT", {
          tipo: "success",
          message: response.data,
        })
      )
      .catch((error) =>
        context.commit("SET_MESSAGE_ALERT", {
          tipo: "error",
          message: error.response.data,
        })
      );
  },
  async listar_especialidades(context) {
    ApiService.setHeader();
    await ApiService.get("especialidade")
      .then((response) => context.commit(SET_LISTA_ESPECIALIDADE, response.data))
      .catch((error) =>
        context.commit("SET_MESSAGE_ALERT", {
          tipo: "error",
          message: error.response.data,
        })
      );
  },
  async create_especialidades(context, value) {
    ApiService.setHeader();
    await ApiService.post("especialidade", value)
      .then((response) => context.commit(response.data))
      .catch((error) =>
        context.commit("SET_MESSAGE_ALERT", {
          tipo: "error",
          message: error.response.data,
        })
      );
  },

  //#region  VINCULO_SALA_ESPECIALIDADE
  async create_vinculo_sala_especialidade(context, value) {
    ApiService.setHeader();
    await ApiService.post("vinculo_especialidade", value)
      .then((response) => context.commit(response.data))
      .catch((error) =>
        context.commit("SET_MESSAGE_ALERT", {
          tipo: "error",
          message: error.response.data,
        })
      );
  },
  async update_vinculo_sala_especialidade(context, value) {
    ApiService.setHeader();
    await ApiService.put("vinculo_especialidade", value)
      .then((response) => context.commit(response.data))
      .catch((error) =>
        context.commit("SET_MESSAGE_ALERT", {
          tipo: "error",
          message: error.response.data,
        })
      );
  },
  async excluir_vinculo_especialidade(context, value) {
    ApiService.setHeader();
    await ApiService.delete("vinculo_especialidade/" + value)
      .then((response) => context.commit(response.data))
      .catch((error) =>
        context.commit("SET_MESSAGE_ALERT", {
          tipo: "error",
          message: error.response.data,
        })
      );
  },
  async list_vinculo_sala_especialidade(context, value) {
    ApiService.setHeader();
    await ApiService.get("vinculo_especialidade", value)
      .then((response) => context.commit(SET_LISTA_VINCULO_ESPECIALIDADE, response.data))
      .catch((error) =>
        context.commit("SET_MESSAGE_ALERT", {
          tipo: "error",
          message: error.response.data,
        })
      );
  },
  async list_vinculo_sala_especialidade_ativos(context, value) {
    ApiService.setHeader();
    await ApiService.get("vinculo_especialidade/ativos", value)
      .then((response) => context.commit(SET_LISTA_VINCULO_ESPECIALIDADE, response.data))
      .catch((error) =>
        context.commit("SET_MESSAGE_ALERT", {
          tipo: "error",
          message: error.response.data,
        })
      );
  },
  //#endregion VINCULO_SALA_ESPECIALIDADE

  async create_empresa(context, value) {
    ApiService.setHeader();
    await ApiService.post("empresa/criar", value)
      .then((response) =>
        context.commit("SET_MESSAGE_ALERT", {
          tipo: "success",
          message: response.data,
        })
      )
      .catch((error) =>
        context.commit("SET_MESSAGE_ALERT", {
          tipo: "error",
          message: error.response.data,
        })
      );
  },
  async update_empresa(context, value) {
    ApiService.setHeader();
    await ApiService.put("empresa/atualizar/" + value.id, value)
      .then((response) =>
        context.commit("SET_MESSAGE_ALERT", {
          tipo: "success",
          message: response.data,
        })
      )
      .catch((error) =>
        context.commit("SET_MESSAGE_ALERT", {
          tipo: "error",
          message: error.response.data,
        })
      );
  },
  async create_ano(context, value) {
    ApiService.setHeader();
    await ApiService.post("ano/criar", value)
      .then((response) =>
        context.commit("SET_MESSAGE_ALERT", {
          tipo: "success",
          message: response.data,
        })
      )
      .catch((error) =>
        context.commit("SET_MESSAGE_ALERT", {
          tipo: "error",
          message: error.response.data,
        })
      );
  },
  async update_ano(context, value) {
    ApiService.setHeader();
    await ApiService.put("ano/atualizar/" + value.id, value)
      .then((response) =>
        context.commit("SET_MESSAGE_ALERT", {
          tipo: "success",
          message: response.data,
        })
      )
      .catch((error) =>
        context.commit("SET_MESSAGE_ALERT", {
          tipo: "error",
          message: error.response.data,
        })
      );
  },
  async create_semestre(context, value) {
    ApiService.setHeader();
    await ApiService.post("semestre/criar", value)
      .then((response) =>
        context.commit("SET_MESSAGE_ALERT", {
          tipo: "success",
          message: response.data,
        })
      )
      .catch((error) =>
        context.commit("SET_MESSAGE_ALERT", {
          tipo: "error",
          message: error.response.data,
        })
      );
  },
  async update_semestre(context, value) {
    ApiService.setHeader();
    await ApiService.put("semestre/atualizar/" + value.id, value)
      .then((response) =>
        context.commit("SET_MESSAGE_ALERT", {
          tipo: "success",
          message: response.data,
        })
      )
      .catch((error) =>
        context.commit("SET_MESSAGE_ALERT", {
          tipo: "error",
          message: error.response.data,
        })
      );
  },
  async bloquear_semestre(context, value) {
    ApiService.setHeader();
    await ApiService.get("semestre/bloquear/" + value.id)
      .then((response) =>
        context.commit("SET_MESSAGE_ALERT", {
          tipo: "success",
          message: response.data,
        })
      )
      .catch((error) =>
        context.commit("SET_MESSAGE_ALERT", {
          tipo: "error",
          message: error.response.data,
        })
      );
  },
  async create_sala(context, value) {
    ApiService.setHeader();
    await ApiService.post("sala/criar", value)
      .then((response) =>
        context.commit("SET_MESSAGE_ALERT", {
          tipo: "success",
          message: response.data,
        })
      )
      .catch((error) =>
        context.commit("SET_MESSAGE_ALERT", {
          tipo: "error",
          message: error.response.data,
        })
      );
  },
  async update_sala(context, value) {
    ApiService.setHeader();
    await ApiService.put("sala/atualizar/" + value.id, value)
      .then((response) =>
        context.commit("SET_MESSAGE_ALERT", {
          tipo: "success",
          message: response.data,
        })
      )
      .catch((error) =>
        context.commit("SET_MESSAGE_ALERT", {
          tipo: "error",
          message: error.response.data,
        })
      );
  },
  async bloquear_sala(context, value) {
    ApiService.setHeader();
    await ApiService.put("sala/bloquear/" + value.id)
      .then((response) =>
        context.commit("SET_MESSAGE_ALERT", {
          tipo: "success",
          message: response.data,
        })
      )
      .catch((error) =>
        context.commit("SET_MESSAGE_ALERT", {
          tipo: "error",
          message: error.response.data,
        })
      );
  },
  async create_local(context, value) {
    ApiService.setHeader();
    await ApiService.post("local/criar", value)
      .then((response) =>
        context.commit("SET_MESSAGE_ALERT", {
          tipo: "success",
          message: response.data,
        })
      )
      .catch((error) =>
        context.commit("SET_MESSAGE_ALERT", {
          tipo: "error",
          message: error.response.data,
        })
      );
  },
  async update_local(context, value) {
    ApiService.setHeader();
    await ApiService.put("local/atualizar/" + value.id, value)
      .then((response) =>
        context.commit("SET_MESSAGE_ALERT", {
          tipo: "success",
          message: response.data,
        })
      )
      .catch((error) =>
        context.commit("SET_MESSAGE_ALERT", {
          tipo: "error",
          message: error.response.data,
        })
      );
  },
  async bloquear_local(context, value) {
    ApiService.setHeader();
    await ApiService.put("local/bloquear/" + value.id)
      .then((response) =>
        context.commit("SET_MESSAGE_ALERT", {
          tipo: "success",
          message: response.data,
        })
      )
      .catch((error) =>
        context.commit("SET_MESSAGE_ALERT", {
          tipo: "error",
          message: error.response.data,
        })
      );
  },
  async create_setor(context, value) {
    ApiService.setHeader();
    await ApiService.post("setor/criar", value)
      .then((response) =>
        context.commit("SET_MESSAGE_ALERT", {
          tipo: "success",
          message: response.data,
        })
      )
      .catch((error) =>
        context.commit("SET_MESSAGE_ALERT", {
          tipo: "error",
          message: error.response.data,
        })
      );
  },
  async update_setor(context, value) {
    ApiService.setHeader();

    await ApiService.put("setor/atualizar/" + value.id, value)
      .then((response) =>
        context.commit("SET_MESSAGE_ALERT", {
          tipo: "success",
          message: response.data,
        })
      )
      .catch((error) =>
        context.commit("SET_MESSAGE_ALERT", {
          tipo: "error",
          message: error.response.data,
        })
      );
  },
  async bloquear_setor(context, value) {
    ApiService.setHeader();

    await ApiService.put("setor/bloquear/" + value.id)
      .then((response) =>
        context.commit("SET_MESSAGE_ALERT", {
          tipo: "success",
          message: response.data,
        })
      )
      .catch((error) =>
        context.commit("SET_MESSAGE_ALERT", {
          tipo: "error",
          message: error.response.data,
        })
      );
  },
  async create_perfil(context, value) {
    ApiService.setHeader();
    await ApiService.post("perfil/criar", value)
      .then((response) =>
        context.commit("SET_MESSAGE_ALERT", {
          tipo: "success",
          message: response.data,
        })
      )
      .catch((error) =>
        context.commit("SET_MESSAGE_ALERT", {
          tipo: "error",
          message: error.response.data,
        })
      );
  },
  async update_perfil(context, value) {
    ApiService.setHeader();

    await ApiService.put("perfil/atualizar/" + value.id, value)
      .then((response) =>
        context.commit("SET_MESSAGE_ALERT", {
          tipo: "success",
          message: response.data,
        })
      )
      .catch((error) =>
        context.commit("SET_MESSAGE_ALERT", {
          tipo: "error",
          message: error.response.data,
        })
      );
  },
  async bloquear_perfil(context, value) {
    ApiService.setHeader();

    await ApiService.get("perfil/bloquear/" + value.id)
      .then((response) =>
        context.commit("SET_MESSAGE_ALERT", {
          tipo: "success",
          message: response.data,
        })
      )
      .catch((error) =>
        context.commit("SET_MESSAGE_ALERT", {
          tipo: "error",
          message: error.response.data,
        })
      );
  },

  //#region DIAGNOSTICO

  async create_diagnostico(context, value) {
    ApiService.setHeader();
    await ApiService.post("diagnostico", value)
      .then((response) =>
        context.commit("SET_MESSAGE_ALERT", {
          tipo: "success",
          message: response.data,
        })
      )
      .catch((error) =>
        context.commit("SET_MESSAGE_ALERT", {
          tipo: "error",
          message: error.response.data,
        })
      );
  },

  async listar_diagnosticos(context) {

    ApiService.setHeader();
    await ApiService.get("diagnostico")
      .then((response) => context.commit(SET_LISTA_DIAGNOSTICO, response.data))
      .catch((error) =>
        context.commit("SET_MESSAGE_ALERT", {
          tipo: "error",
          message: error.response.data,
        })
      );
  },

  async pesquisar_diagnosticos(context, filtro) {
    ApiService.setHeader();
    await ApiService.get("diagnostico/pesquisa/"+filtro)
      .then((response) => context.commit(SET_LISTA_DIAGNOSTICO, response.data))
      .catch((error) =>
        context.commit("SET_MESSAGE_ALERT", {
          tipo: "error",
          message: error.response.data,
        })
      );
  },
  //#endregion


  async listar_empresas(context, value) {
    ApiService.setHeader();
    await ApiService.get("empresa")
      .then((response) => context.commit(SET_LISTA_EMPRESAS, response.data))
      .catch((error) =>
        context.commit("SET_MESSAGE_ALERT", {
          tipo: "error",
          message: error.response.data,
        })
      );
  },
  async listar_filial(context) {
    ApiService.setHeader();
    await ApiService.get("filial")
      .then((response) => context.commit(SET_LISTA_FILIALS, response.data))
      .catch((error) =>
        context.commit("SET_MESSAGE_ALERT", {
          tipo: "error",
          message: error.response.data,
        })
      );
  },
  async listar_filial_empresa(context) {
    ApiService.setHeader();
    await ApiService.get("filial/empresa")
      .then((response) => context.commit(SET_LISTA_FILIALS, response.data))
      .catch((error) =>
        context.commit("SET_MESSAGE_ALERT", {
          tipo: "error",
          message: error.response.data,
        })
      );
  },
  limpar_lista_produtos(context) {
    context.commit("CLEAN_ITENS_PRODUTO");
  },
  async listar_convocatoria(context, value) {
    ApiService.setHeader();
    await ApiService.get("convocatoria")
      .then((response) =>
        context.commit(SET_LISTA_CONVOCATORIAS, response.data)
      )
      .catch((error) =>
        context.commit("SET_MESSAGE_ALERT", {
          tipo: "error",
          message: error.response.data,
        })
      );
  },
  async listar_anos(context, value) {
    ApiService.setHeader();
    await ApiService.get("ano")
      .then((response) => context.commit(SET_LISTA_ANOS, response.data))
      .catch((error) =>
        context.commit("SET_MESSAGE_ALERT", {
          tipo: "error",
          message: error.response.data,
        })
      );
  },
  async listar_semestres(context, value) {
    ApiService.setHeader();

    await ApiService.get("semestre")
      .then((response) => context.commit(SET_LISTA_SEMESTRES, response.data))
      .catch((error) =>
        context.commit("SET_MESSAGE_ALERT", {
          tipo: "error",
          message: error.response.data,
        })
      );
  },

  async listar_semestres_anual(context, value) {
    ApiService.setHeader();
    await ApiService.get("semestre_anual")
      .then((response) =>
        context.commit(SET_LISTA_SEMESTRES_ANUAL, response.data)
      )
      .catch((error) =>
        context.commit("SET_MESSAGE_ALERT", {
          tipo: "error",
          message: error.response.data,
        })
      );
  },
  async listar_semestre_oferta_disciplina(context, value) {
    ApiService.setHeader();
    await ApiService.get("semestre_oferta_disciplina")
      .then((response) =>
        context.commit(SET_LISTA_SEMESTRE_OFERTA_DISCIPLINA, response.data)
      )
      .catch((error) =>
        context.commit("SET_MESSAGE_ALERT", {
          tipo: "error",
          message: error.response.data,
        })
      );
  },
  async listar_semestre_letivo(context, value) {
    ApiService.setHeader();
    await ApiService.get("semestre_letivo/matricula/" + value)
      .then((response) =>
        context.commit(SET_LISTA_SEMESTRE_LETIVO, response.data)
      )
      .catch((error) =>
        context.commit("SET_MESSAGE_ALERT", {
          tipo: "error",
          message: error.response.data,
        })
      );
  },
  async listar_sala(context, value) {
    ApiService.setHeader();
    await ApiService.get("sala")
      .then((response) => context.commit(SET_LISTA_SALA, response.data))
      .catch((error) =>
        context.commit("SET_MESSAGE_ALERT", {
          tipo: "error",
          message: error.response.data,
        })
      );
  },

  async listar_local(context, value) {
    ApiService.setHeader();
    await ApiService.get("local")
      .then((response) => context.commit(SET_LISTA_LOCAL, response.data))
      .catch((error) =>
        context.commit("SET_MESSAGE_ALERT", {
          tipo: "error",
          message: error.response.data,
        })
      );
  },
  async listar_setor(context, value) {
    ApiService.setHeader();
    await ApiService.get("setor")
      .then((response) => context.commit(SET_LISTA_SETOR, response.data))
      .catch((error) =>
        context.commit("SET_MESSAGE_ALERT", {
          tipo: "error",
          message: error.response.data,
        })
      );
  },
  async listar_perfil(context, value) {
    ApiService.setHeader();
    await ApiService.get("perfil")
      .then((response) => context.commit(SET_LISTA_PERFIL, response.data))
      .catch((error) =>
        context.commit("SET_MESSAGE_ALERT", {
          tipo: "error",
          message: error.response.data,
        })
      );
  },
  async pegarLive(context, value) {
    ApiService.setHeader();

    await ApiService.get("lives/" + value.id)
      .then((response) => {
        context.commit("SET_CAMPOS", response.data);
        console.log(response.data);
      })
      .catch((error) => {
        context.commit("SET_MESSAGE_ALERT", {
          tipo: "error",
          message: error.response.data,
        });
      });
  },

  MudarPreloader(context, value) {
    context.commit("SET_PRELOADER", value);
  },
  atualizar(context, value) {
    context.commit("SET_CAMPOS", value);
  },
  guardarPaciente(context, value) {
    context.commit("SET_PACIENTE", value);
  },
  guardarDados(context, value) {
    context.commit("SET_DADOS_GUARDADOS", value);
  },
  aluno_id(context, value) {
    context.commit("SET_DADOS_ALUNO", value);
  },
  semestre_id(context, value) {
    context.commit("SET_DADOS_SEMESTRE_ID", value);
  },
  gerar_excel_funcionarios(context) {
    ApiService.get("usuario/funcionario", {
      responseType: 'blob'
    }).then(response => {
      let fileUrl = window.URL.createObjectURL(response.data);
      let fileLink = document.createElement('a');

      fileLink.href = fileUrl;
      fileLink.setAttribute('download', 'relatorio_transferencias.xls');
      document.body.appendChild(fileLink)

      fileLink.click();
    }).catch((error) => context.commit(SET_MESSAGE_ALERT, {
      tipo: 'error', message: error.response.data
    }))
  },
};

const getters = {};

const mutations = {
  [SET_LISTA_EMPRESAS](state, value) {
    state.lista_empresas = value;
  },
  [SET_LISTA_DIAGNOSTICO](state, value) {
    state.lista_diagnosticos = value;
  },
  [SET_LISTA_ESPECIALIDADE](state, value) {
    state.lista_especialidades = value;
  },
  [SET_LISTA_VINCULO_ESPECIALIDADE](state, value) {
    state.lista_vinculo_especialidade = value;
  },
  [SET_LISTA_FILIALS](state, value) {
    state.lista_filials = value;
  },
  [SET_LISTA_CONVOCATORIAS](state, value) {
    state.lista_convocatorias = value;
  },
  [SET_LISTA_SEMESTRES](state, value) {
    state.lista_semestres = value;
  },
  [SET_LISTA_ANOS](state, value) {
    state.lista_anos = value;
  },
  [SET_LISTA_SEMESTRES_ANUAL](state, value) {
    state.lista_semestre_anual = value;
  },
  [SET_LISTA_SEMESTRE_OFERTA_DISCIPLINA](state, value) {
    state.lista_semestre_oferta_disciplina = value;
  },
  [SET_LISTA_SEMESTRE_LETIVO](state, value) {
    state.lista_semestre_letivo = value;
  },
  [SET_LISTA_SALA](state, value) {
    state.lista_sala = value;
  },
  [SET_LISTA_PRODUTO](state, value) {
    state.lista_sala = value;
  },
  [SET_LISTA_LOCAL](state, value) {
    state.lista_local = value;
  },
  [SET_LISTA_SETOR](state, value) {
    state.lista_setor = value;
  },
  [SET_LISTA_PERFIL](state, value) {
    state.lista_perfil = value;
  },
  SET_PRELOADER(state, value) {
    state.isLoading = value;
  },
  SET_CAMPOS(state, value) {

    state.lista_campos = value;

  },
  SET_PACIENTE(state, value) {
    state.paciente_id = value;
  },

  SET_DADOS_GUARDADOS(state, value) {
    state.lista_dados_guardados = value;
  },
  SET_MESSAGE_ALERT(state, value) {
    let customMessage;
    if (typeof value.message === "object") {
      let item = Object.entries(value.message)[0];
      customMessage = `${item[0]}: ${item[1][0]}`;
    }
    state.mensagem_alert = {
      tipo: value.tipo,
      message: customMessage ? customMessage : value.message,
    };
  },
  SET_DADOS_ALUNO(state, value) {
    state.aluno_id = value;
  },
  SET_DADOS_SEMESTRE_ID(state, value) {
    state.semestre_id = value;
  },
};

const state = {
  lista_empresas: [],
  lista_filials: [],
  lista_semestres: [],
  lista_anos: [],
  lista_semestre_anual: [],
  lista_convocatorias: [],
  lista_semestre_oferta_disciplina: [],
  lista_semestre_letivo: [],
  isLoading: false,
  lista_campos: "",
  paciente_id: "",
  ocorrencia_id: "",
  mensagem_alert: "",
  lista_dados_guardados: [],
  lista_sala: [],
  lista_local: [],
  lista_setor: [],
  lista_perfil: [],
  aluno_id: "",
  semestre_id: "",
  lista_especialidades: "",
  lista_diagnosticos: [],
  lista_vinculo_especialidade: []
};

export default {
  namespaced: true,
  actions,
  getters,
  mutations,
  state,
};
