import ApiService from "@/core/services/api.service";

export const SET_LISTA_AULAS = "setListAulas"
export const SET_LISTA_DISCIPLINAS_MATRICULADAS = 'setListDisciplinasMatriculadas'
export const SET_LISTA_AULAS_EXTRAS = 'setListAulasExtras'
export const SET_LISTA_MATERIAL = 'setListMaterial'
export const SET_LISTA_MATERIAL_EXTRA = 'setListMaterialExtra'
export const SET_LISTA_LIVES = 'setListLives'
export const SET_LISTA_ARQUIVOS = 'setListArquivos'
export const SET_LISTA_PERGUNTAS = 'setListPerguntas'
export const SET_DADOS_PERGUNTA = 'setDadosPergunta'
export const SET_LISTA_AVALIACOES = 'setListaAvaliacoes'
export const SET_LISTA_TIPO_AVALIACOES = 'setListTipoAvaliacoes'
export const SET_LISTA_AVALIACAO_OFERTA_DISCIPLINA = 'setListAvaliacoesOfertaDisciplina'
export const SET_LISTA_PERGUNTAS_ADICIONADAS = 'setListPerguntasAdicionadas'
export const SET_NOTAS_ALUNO = "setListNotasAluno"
export const SET_LISTA_TENTATIVAS = "setListTentativas" 




const actions = {
 


//arquivo
    async create_arquivo(context, value) {

        await ApiService.post('arquivo/criar', value)
            .then(response => context.commit('SET_MESSAGE_ALERT', {
                tipo: 'success',
                message: response.data
            }))
            .catch((error) => context.commit('SET_MESSAGE_ALERT', {
                tipo: 'error',
                message: error.response.data
            }))
    },

    async update_arquivo(context, value) {

        await ApiService.post('arquivo/update/' + value.id, value)
            .then(response => context.commit('SET_MESSAGE_ALERT', {
                tipo: 'success',
                message: response.data
            }))
            .catch((error) => context.commit('SET_MESSAGE_ALERT', {
                tipo: 'error',
                message: error.response.data
            }))
    },

    async listar_arquivos(context) {
        await ApiService.get('arquivo')
            .then(response => {
                context.commit(SET_LISTA_ARQUIVOS, response.data)
            })
            .catch((error) => {
                context.commit('SET_MESSAGE_ALERT', {
                    tipo: 'error',
                    message: error.response.data
                })
            })
    },
 
    atualizarPergunta2(context){
        context.commit(SET_DADOS_PERGUNTA, '')
    },
    async update_perguntas(context, value) {

        await ApiService.post('pergunta/atualizar', value)
            .then(response => context.commit('SET_MESSAGE_ALERT', {
                tipo: 'success',
                message: response.data
            }))
            .catch((error) => context.commit('SET_MESSAGE_ALERT', {
                tipo: 'error',
                message: error.response.data
            }))
    },


        async listar_tipo_avaliacao(context,){

            await ApiService.get('tipo_avaliacao')
                
                    .then(response => {
                        context.commit(SET_LISTA_TIPO_AVALIACOES, response.data)
                    })
                    .catch((error) => {
                        context.commit('SET_MESSAGE_ALERT', {
                            tipo: 'error',
                            message: error.response.data
                        })
                    })
        
                },

                
 


};

const getters = {};

const mutations = {
    
    [SET_LISTA_ARQUIVOS](state, value) {
        state.lista_arquivo = value
    },
  
    


    


};

const state = {

    lista_arquivo: [],



};

export default {
    namespaced:true,
    state,
    actions,
    mutations,
    getters
};